import React, { Fragment, useEffect, useState } from "react";
import { Breadcrumbs, VerticalLinkGroup } from "components/navigation";
import { UserAccess, UserHasAccess } from "components/user-access";
import { PageRestricted } from "pages/errors/page-restricted";
import { Redirect, Route, Switch, useParams } from "react-router-dom";
import { DefectReporting } from "pages/walkaround-check/shared/DefectReporting";
import { AssetService, LinkService } from "services";
import { MotTests } from "pages/asset/mots";
import { SafetyFeatures } from "pages/asset/safety-features";
import { Profile } from "pages/asset/profile";
import { Preferences } from "pages/asset/preferences";
import { Intent, Position } from '@blueprintjs/core';
import { AlertConfirm, NotificationToaster } from "components/notifications";
import { AssetStatus } from "components/status/AssetStatus";
import { Button, LinkButton } from "components/buttons";
import { ShowHide } from "components/layout";
import { AssociatedAssets } from "pages/asset/associated-assets";
import { HelperFunctions } from "helpers";
import classNames from "classnames";
import axios from 'axios';
import assetTypeDisplay from './AssetTypeDisplay.json';

export function Asset(props) {
    const { id, tab } = useParams();
    const requiredActions = ["Fleet:View", "FleetPCN:View", "FleetVIS:View"];
    const deleteAssetAction = "Internal:Super";
    const badRequestCode = 400;
    const mmfCookie = 'Use_New_MMF';
    const tabsToIgnoreRedirect = ["mot-tests", "walkaround-checks", "safety-features"];
    const queryParams = new URLSearchParams(window.location.search)
    const alertBreadCrumbs = [{ location: '/alert-type', title: "Alert Types" }, { location: `/alert/${queryParams.get("alertType")}?pageNo=${queryParams.get("pageNo")}&searchTerm=${queryParams.get("searchTerm")}`, title: "Alert List" }, { title: "Profile" }];
    const canAccessPage = UserHasAccess(requiredActions);
    const [asset, setAsset] = useState({});
    const [assetOptions, setAssetOptions] = useState({});
    const [loadingData, setLoadingData] = useState(true);
    const [savingData, setSavingData] = useState(false);
    const [assetStatusesMappings, setAssetStatusesMappings] = useState([]);
    const [ownershipAttributeMappings, setOwnershipAttributeMappings] = useState([]);
    const [isDeleteAssetModalOpen, setIsDeleteAssetModalOpen] = useState(false);
    const [isInActiveStatus, setIsInActiveStatus] = useState(false);
    const [reloadPage, setReloadPage] = useState(false);
    const [isDeletingAsset, setIsDeletingAsset] = useState(false)

    const allRoutes = [
        {
            location: `${LinkService.getVehicleProfileUrl(id, "details")}`,
            title: "Details",
            requiredAction: requiredActions
        },
        {
            location: `${LinkService.getVehicleProfileUrl(id, "preferences")}`,
            title: "Preferences",
            requiredAction: requiredActions
        },
        {
            location: `/asset/${id}/associated-assets`,
            title: "Associated Assets",
            requiredAction: requiredActions
        },
        {
            location: `${window.env.VISION_URL}/#/Fleet/RedirectToProfile?assetId=${id}&tab=inspectionsTab`,
            title: "Inspections",
            externalLink: true,
            requiredAction: ['PlannedMaintenance:View', 'AuditsInspections:View']
        },
        {
            location: `/asset/${id}/mot-tests`,
            title: "MOT Tests",
            requiredAction: requiredActions,
        },
        {
            location: `${window.env.VISION_URL}/#/Fleet/RedirectToProfile?assetId=${id}&tab=tab-history`,
            title: "History",
            externalLink: true,
            requiredAction: requiredActions
        },
        {
            location: `${window.env.VISION_URL}/#/Fleet/RedirectToProfile?assetId=${id}&tab=pcnTab`,
            title: "PCN",
            externalLink: true,
            requiredAction: "FleetPCN:View"
        },
        {
            location: `/asset/${id}/walkaround-checks`,
            title: "Walkaround Checks",
            requiredAction: "WalkaroundCheck:View",
        },
        {
            location: `/asset/${id}/safety-features`,
            title: "Safety Features",
            requiredAction: requiredActions,
        },
    ];

    const routesForUser = allRoutes.filter((x) =>
        x.requiredAction ? UserHasAccess(x.requiredAction) : true
    );

    function saveAssetProfile(value, reload) {
        setSavingData(true);
        AssetService.saveAssetProfile(value).then(() => {
            setAsset(value);
            if (reload) {
                setReloadPage(true);
            }
            NotificationToaster.show(Intent.SUCCESS, `Profile updated successfully.`);
        }).catch((error) => {
            if (error?.status === badRequestCode) {
                NotificationToaster.show(Intent.WARNING, error.data);
            } else {
                NotificationToaster.show(Intent.DANGER, "Failed to save profile data. Please try again.");
            }
        }).finally(() => {
            setSavingData(false);
            setReloadPage(false);
        });
    }
    useEffect(() => {
        const cookieExists = HelperFunctions.checkForCookie(mmfCookie);
        if (!cookieExists && !tabsToIgnoreRedirect.includes(tab)) {
            if (tab) {
                window.location.href = LinkService.getVehicleProfileUrl(id, tab);
            }
            else {
                window.location.href = LinkService.getVehicleProfileUrl(id, "details");
            }
        }
    }, [tab])


    useEffect(() => {
        if (!canAccessPage || (assetOptions.length > 1 && !reloadPage)) {
            return;
        }

        setLoadingData(true);

        axios.all([
            AssetService.getAsset(id),
            AssetService.getUploadSummary(id),
            AssetService.getAssetOptions()
        ]).then(axios.spread(function (getAssetResponse, getUploadSummaryResponse, getAssetOptionsResponse) {
            if (getAssetResponse != null) {
                //Map the upload to the profile model as it's all just read only
                getAssetResponse.lastUploadDate = getUploadSummaryResponse.uploadDate;
                getAssetResponse.lastActivityDate = getUploadSummaryResponse.lastActivityDate;
                getAssetResponse.lastDownloadDate = getUploadSummaryResponse.downloadDate;
                setAsset(getAssetResponse);
                setIsInActiveStatus(getAssetResponse.assetLocation.assetStatus.id === 2);
            }

            if (getAssetOptionsResponse != null) {
                setAssetOptions(getAssetOptionsResponse);
                setOwnershipAttributeMappings(getAssetOptionsResponse.assetOwnerships.map((x) => {
                    return { "name": x.name, "colours": x.colour, "id": x.id, "className": x.style, "icon": x.icon }
                }));
                setAssetStatusesMappings(getAssetOptionsResponse.assetStatuses.map((x) => {
                    return { "name": x.name, "colours": x.colour, "id": x.id }
                }));
            }
        })).catch(() => {
            NotificationToaster.show(Intent.DANGER, `Failed to load profile data. Please refresh the page.`);
        }).finally(() => {
            setLoadingData(false);
        });
    }, [id, reloadPage]);

    function showDeleteAssetModal() {
        setIsDeleteAssetModalOpen(true);
    }

    function hideDeleteAssetModal() {
        setIsDeletingAsset(false);
        setIsDeleteAssetModalOpen(false);
    }

    function onDeleteAsset() {
        setIsDeletingAsset(true);
        AssetService.deleteAsset(id).then(() => {
            NotificationToaster.show(Intent.SUCCESS, `Asset deleted successfully.`);
            window.location.href = "/fleet";
        }).catch(() => {
            NotificationToaster.show(Intent.DANGER, `Failed to delete asset. Please try again.`);
        }).finally(() => {
            setIsDeletingAsset(false);
        });
    }

    return (
        <UserAccess
            perform={requiredActions}
            yes={() => (
                <Fragment>
                    <div className="row">
                        <Breadcrumbs items={queryParams.get("alertType") === null ? props.breadcrumbs : alertBreadCrumbs} />
                        <div className={classNames({ "bp3-skeleton": loadingData }, 'inline-items', 'space-large', 'spacer-bottom-medium')}>
                            <AssetStatus statusId={asset?.assetLocation?.assetStatus?.id} ownershipId={asset?.assetLocation?.assetOwnership?.id} isLoading={loadingData} statusAttributeMappings={assetStatusesMappings} ownershipAttributeMappings={ownershipAttributeMappings} tooltipPosition={Position.RIGHT} />
                            <h1 className="no-margin">{asset?.registration}{asset?.assetLocation?.fleetNumber ? ` / ${asset?.assetLocation?.fleetNumber}` : ''}</h1>
                        </div>
                        <div className="inline-items inline-items-space space-large spacer-bottom-large">
                            <LinkButton icon="calendar" intent="primary" text="Calendar" href={`/fleet/${id}/calendar`} />
                            <UserAccess
                                perform={deleteAssetAction}
                                yes={() => (
                                    <ShowHide
                                        evaluator={isInActiveStatus}
                                        show={
                                            <Button icon="trash" intent="delete" text="Delete Asset" onClick={showDeleteAssetModal} />
                                        }
                                    />
                                )}
                            />
                        </div>
                    </div>

                    <AlertConfirm
                        title="Delete Asset"
                        isOpen={isDeleteAssetModalOpen}
                        onConfirm={onDeleteAsset}
                        onCancel={hideDeleteAssetModal}
                        confirmButtonIntent="delete"
                        confirmButtonText="Delete Asset"
                        disabled={isDeletingAsset}
                    >
                        <p>Are you sure you want to delete this asset?</p>
                    </AlertConfirm>

                    <div className="layout-cols">
                        <div className="col side-col">
                            <div className="row">
                                <VerticalLinkGroup links={routesForUser} exactRouteMatch={false} />
                            </div>
                        </div>
                        <div className="col content-col">
                            <Switch>
                                <Redirect
                                    exact
                                    from="/asset/:id(\d+)"
                                    to="/asset/:id(\d+)/details"
                                />
                                <Route path="/asset/:id(\d+)/details">
                                    <Profile assetProfile={asset}
                                        assetTypeDisplay={assetTypeDisplay}
                                        assetOptions={assetOptions}
                                        loading={loadingData}
                                        saving={savingData}
                                        saveProfile={saveAssetProfile}
                                        key={id} />
                                </Route>
                                <Route path="/asset/:id(\d+)/preferences">
                                    <Preferences
                                        assetProfile={asset}
                                        saveProfile={saveAssetProfile}
                                        assetTypeDisplay={assetTypeDisplay}
                                        assetOptions={assetOptions}
                                        loading={loadingData}
                                        saving={savingData}
                                    />
                                </Route>
                                <Route path="/asset/:id(\d+)/associated-assets">
                                    <AssociatedAssets assetProfile={asset}
                                        assetTypeDisplay={assetTypeDisplay}
                                        assetOptions={assetOptions}
                                        assetStatusesMappings={assetStatusesMappings}
                                        ownershipAttributeMappings={ownershipAttributeMappings}
                                        loading={loadingData}
                                        saving={savingData}
                                        saveProfile={saveAssetProfile}
                                        key={id}
                                    />
                                </Route>
                                <Route path="/asset/:id(\d+)/walkaround-checks">
                                    <DefectReporting
                                        id={id}
                                        route={"asset"}
                                        sortName={"employeeFullName"}
                                        headerName={"Completed by"}
                                    />
                                </Route>
                                <Route path="/asset/:id(\d+)/mot-tests">
                                    <MotTests
                                        asset={asset}
                                        assetId={id}
                                        key={id}
                                    />
                                </Route>
                                <Route path="/asset/:id(\d+)/safety-features">
                                    <SafetyFeatures assetId={id} key={id} />
                                </Route>
                            </Switch>
                        </div>
                    </div>
                </Fragment>
            )}
            no={() => <PageRestricted />}
        />
    );
}
