import { useEffect, useState } from "react";
import { ButtonSave } from "../../components/buttons";
import { AlertBasic, AlertUnsavedData, NotificationToaster } from "../../components/notifications";
import { FormSwitchList } from "../../components/form-fields";
import Cookies from 'js-cookie';
import "./BetaFeatures.css"
import { useDirtyData } from "../../hooks/useDirtyData";
import { UserHasAccessActions } from 'components/user-access';
import { Intent } from "@blueprintjs/core";
import { useSelector } from "react-redux";

export function BetaFeatures(props) {

    const [initialBetaFeatures, setInitialBetaFeatures] = useState([]);
    const [betaFeatures, setBetaFeatures] = useState([]);
    const [showSwitchAlert, setShowSwitchAlert] = useState(false);
    const cookieExpiresDays = 1_095;

    useEffect(() => {
        const initialFeatures = [
            {
                id: 1,
                name: "Home Page",
                cookieName: 'Use_New_Home_Page',
                blurb: "Enable if you would like to use the new Vision Home Page.",
                feedBackLink: "https://surveys.hotjar.com/54857559-d786-4912-a3e5-3d457fe6e890",
                checked: checkForCookie('Use_New_Home_Page'),
                role: []
            },
            {
                id: 2,
                name: "Manage My Fleet",
                cookieName: 'Use_New_MMF',
                blurb: "Enable if you would like to use the new Manage My Fleet page. Enabling will also enable Asset Calendar and Day Detail.",
                feedBackLink: "https://surveys.hotjar.com/7fc0aeb2-60e3-4b8e-8ac4-3b079957eac6",
                checked: checkForCookie('Use_New_MMF'),
                role: ["Fleet:View"]
            }
        ];
        setBetaFeatures(initialFeatures);
        setInitialBetaFeatures(initialFeatures);
    }, []);

    const isDataDirty = useDirtyData(initialBetaFeatures, betaFeatures);
    
    function onProviderUpdate(event) {
        const toggleId = parseInt(event.currentTarget.getAttribute('identifier'));
        const checked = event.target.checked;

        const updatedFeatures = betaFeatures.map((feature) =>
            feature.id === toggleId ? { ...feature, checked: checked } : feature
        );
        setBetaFeatures(updatedFeatures);

        const originalFeature = initialBetaFeatures.find(f => f.id === toggleId);

        if (!checked && originalFeature.checked) {
            setShowSwitchAlert(true);
        }
    }

    function checkForCookie(cookieName) {
        return Cookies.get(cookieName) !== undefined;
    }

    function removeCookie(feature) {
        Cookies.remove(feature.cookieName, { domain: window.env.DOMAIN });
    }
    const userActions = useSelector(state => state.loggedInUser.actions);
    function handleSave() {
        if (isDataDirty) {
            betaFeatures.forEach(
                (feature) => {
                    if (initialBetaFeatures.find(f => f.id === feature.id).checked !== feature.checked) {
                        if (feature.checked) {
                            Cookies.set(feature.cookieName, 'true', { domain: window.env.DOMAIN, expires: cookieExpiresDays });
                        }
                        else {
                            removeCookie(feature);
                        }  
                    } 
                }
            );
            NotificationToaster.show(Intent.SUCCESS, "Preferences successfully updated");
            setInitialBetaFeatures(betaFeatures);
        }
    }

    function closeAlertModal() {
        setShowSwitchAlert(false);
    }

    function mapFeatures() {
        return betaFeatures
            .filter(x => x.role.length === 0 || UserHasAccessActions(x.role, userActions))
            .map(function (f) {
            const labelContent = (
                <>
                    <h3>{f.name}</h3>
                    <div>{f.blurb}</div>
                    <p></p>
                    <a href={f.feedBackLink} target="_blank"> Please tell us your thoughts</a>
                </>
            );

            return {
                id: f.id.toString(),
                labelElement: labelContent,
                checked: f.checked
            };
        });
    }


    return (
        <>
            <div
                className="beta-features-wrapper">
                <h1>Beta Features</h1>
                <p className="spacer-bottom">Features that are currently in beta. Please select which features you would like to enable.</p>
                <FormSwitchList
                    className="beta-features-list"
                    items={mapFeatures()}
                    onChange={onProviderUpdate}
                />
                <ButtonSave
                    className="beta-features-save"
                    onClick={handleSave}
                    simpleDisabled={!isDataDirty}
                >Save</ButtonSave>
                <AlertUnsavedData isDirty={isDataDirty} />
                <AlertBasic isOpen={showSwitchAlert} intent={Intent.PRIMARY} handleAlertConfirm={closeAlertModal}>
                    <p>Switching back? We want to hear from you. Please click the feedback link to tell us your thoughts.</p>
                </AlertBasic>
            </div>
        </>
    );

}
