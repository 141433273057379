import React, { useState, useEffect } from "react";
import { FormHeading, FormNumericInput, FormSelect } from "components/form-fields";
import { Button, LinkButton } from "components/buttons";
import { Breadcrumbs, Tabs } from "components/navigation";
import { OperatingLicenceService } from "services";
import { useParams, useHistory } from "react-router-dom";
import { Icon } from "@blueprintjs/core";
import { PageRestricted } from "pages/errors/page-restricted";
import { UserAccess } from "components/user-access";
import { Collapsable, ShowHide } from "components/layout";

import '../OperatingLicences.css';
import { DateToLocal } from "components/dates";
import { AlertConfirm, AlertUnsavedData, Modal, NotificationInline, NotificationToaster } from "components/notifications";
import { Table } from "components/listing";
import { Numeric } from "components/formatting";
import { GridLayout } from "components/grid";
import classNames from "classnames";
import { useDirtyData } from "hooks/useDirtyData";
import axios from "axios";

export function OperatingLicenceView(props) {

    const history = useHistory();
    const { id } = useParams();

    const [tabs, setTabs] = useState([]);

    const [loadingData, setLoadingData] = useState(true);

    const [operatingLicences, setOperatingLicences] = useState([]);
    const [invalidLicence, setInvalidLicence] = useState(false);
    const requiredAction = "OperatingLicence:View";

    const [operatingLicenceOptions, setOperatingLicenceOptions] = useState(null);
    const [currentTrafficCommisioner, setCurrentTrafficCommisioner] = useState(null);

    const [currentLicence, setCurrentLicence] = useState({});
    const [currentListing, setCurrentListing] = useState({});
    const [editLicenceModel, setEditLicenceModel] = useState({});
    const [originalEditLicenceModel, setOriginalEditLicenceModel] = useState({});

    const [showLicenceInfo, setShowLicenceInfo] = useState(true);
    const [showOcrsKey, setShowOcrsKey] = useState(false);

    const [showOcrsEdit, setShowOcrsEdit] = useState(false);
    const [savingOcrsScores, setSavingOcrsScores] = useState(false);

    const isLicenceDataDirty = useDirtyData(originalEditLicenceModel, editLicenceModel);
    const [blockModalClose, setBlockModalClose] = useState(false);
    const [ocrsScoresInvalid, setOcrsScoresInvalid] = useState(false);

    useEffect(() => {

        axios.all([
            OperatingLicenceService.getLicences(),
            OperatingLicenceService.getOptions(),
        ]).then(axios.spread(function (licenceResponse, options) {

            setOperatingLicences(licenceResponse.map(function (licence) {
                return {
                    id: licence.operatingLicenceId.toString(),
                    name: licence.isFavourite ? <div className="inline-items" ><div>{licence.licenceArea}</div><div><Icon icon="star" /><span></span></div></div> : licence.licenceArea,
                    ...licence
                }
            }));

            setOperatingLicenceOptions(options);

        }));


    }, []);

    useEffect(() => {

        if (id == null || operatingLicences.length === 0 || operatingLicenceOptions == null) {
            return;
        }

        setInvalidLicence(false);
        let selectedLicence = operatingLicences.find(x => x.id === id);

        if (selectedLicence == null) {
            //url contains an invalid licence
            setInvalidLicence(true);
            return;
        }

        setCurrentListing(selectedLicence)

        //Fetch operating licence details for individual licence
        setLoadingData(true);

        OperatingLicenceService.getLicence(id).then(function (licenceResponse) {
            setCurrentLicence(licenceResponse);

            var editModel = {
                ...licenceResponse,
                licenceArea: licenceResponse.licenceArea.id,
                licenceType: licenceResponse.licenceType.id,
                licenceRenewalDate: licenceResponse.licenceRenewal,
                ocrsRoadScore: licenceResponse.ocrs?.roadScore,
                ocrsTrafficScore: licenceResponse.ocrs?.trafficScore,
                ocrsCombinedScore: licenceResponse.ocrs?.combinedScore,
            };

            delete editModel.ocrs;

            setEditLicenceModel(editModel);
            setOriginalEditLicenceModel(editModel);
            setCurrentTrafficCommisioner(operatingLicenceOptions?.trafficCommissioners?.find(x => x.licenceAreaId === licenceResponse.licenceArea.id));
        }, function () {
            NotificationToaster.show("danger", "Could not load operating licence information, please try again", true);
        }).finally(() => {
            setLoadingData(false);
        });

        //Set the disabled flag on asset list based on whether there are assets or not

        setTabs([
            { title: "Overview", key: "OVERVIEW", selected: true },
            { title: "Asset List", key: "ASSETS", selected: false, disabled: true },
            { title: "Transport Managers", key: "MANAGERS", selected: false }
        ]);

    }, [id, operatingLicences, operatingLicenceOptions]);


    function onOperatingLicenceSelect(item) {
        if (item != null && item.id !== id) {
            history.push(`/operating-licences/${item.id}`)
        }
    }

    function onEditLicence() {
        //launch modal for editing licence details
    }

    function onTabSelected() {
        //switch the content based on the tab index
    }

    function onHeaderToggle() {
        setShowLicenceInfo((prev) => !prev);
    }


    function calculateOcrsBand(score, category) {

        if (score == null) {
            return <span className="ocrs-grey">-</span>;
        }

        const trafficMaxBandGreen = 5;
        const nonTrafficMaxBandGreen = 10;

        let greenBand = category === "TRAFFIC" ? trafficMaxBandGreen : nonTrafficMaxBandGreen;

        if (score <= greenBand) {
            return <span className="ocrs-green"><Numeric>{score}</Numeric></span>;
        }

        const trafficMaxBandAmber = 30;
        const nonTrafficMaxBandAmber = 25;

        let amberBand = category === "TRAFFIC" ? trafficMaxBandAmber : nonTrafficMaxBandAmber;

        if (score > amberBand) {
            return <span className="ocrs-red"><Numeric>{score}</Numeric></span>;
        }

        return <span className="ocrs-amber"><Numeric>{score}</Numeric></span>;

    }

    function onOcrsScoreUpdate(newValue, field) {

        setOcrsScoresInvalid(false);

        let parsedValue = newValue === "" ? null : newValue;

        if(parsedValue != null && parsedValue < 0) {
            setOcrsScoresInvalid(true);
        }

        setEditLicenceModel((prevValue) => {

            let clonedScores = { ...prevValue }
            clonedScores[field] = parsedValue;

            return clonedScores;

        })

    }

    function onOcrsEditModalCancel(forceClose = false) {

        setBlockModalClose(false);

        if (isLicenceDataDirty && !forceClose) {
            setBlockModalClose(true);
            return;
        }

        setEditLicenceModel((prevValue) => {

            return {
                ...prevValue,
                ocrsRoadScore: currentLicence.ocrs?.roadScore,
                ocrsTrafficScore: currentLicence.ocrs?.trafficScore,
                ocrsCombinedScore: currentLicence.ocrs?.combinedScore
            };

        });
        setShowOcrsEdit(false);
    }

    function onSaveOcrsScores() {
        setSavingOcrsScores(true);

        OperatingLicenceService.updateOperatingLicence(id, editLicenceModel).then(function () {
            NotificationToaster.show("success", "OCRS scores successfully updated", true);
            setShowOcrsEdit(false);
            setOriginalEditLicenceModel(editLicenceModel);
            setCurrentLicence((prevValue) => {
                return {
                    ...prevValue,
                    ocrs: {
                        roadScore: editLicenceModel.ocrsRoadScore,
                        trafficScore: editLicenceModel.ocrsTrafficScore,
                        combinedScore: editLicenceModel.ocrsCombinedScore,
                    }
                }
            });
        }, function (error) {
            const badRequestCode = 400;

            if (error.status === badRequestCode) {
                NotificationToaster.show("warning", error.data, true);
            } else {
                NotificationToaster.show("danger", "Could not update OCRS scores, please try again", true);
            }

        }).finally(function () {
            setSavingOcrsScores(false);
        })
    }

    return (
        <UserAccess
            perform={requiredAction}
            yes={() => (
                <div>
                    <Breadcrumbs items={props.breadcrumbs} />

                    <ShowHide
                        evaluator={invalidLicence}
                        hide={(
                            <div>
                                <h1>Operating Licences</h1>
                                <div className="spacer-bottom inline-items">
                                    <FormSelect items={operatingLicences} selectedValue={id} scrollable onItemSelect={onOperatingLicenceSelect} disabled={loadingData}></FormSelect>
                                    <LinkButton text="Licence List View" intent="primary" href="/operating-licences?redirect=0" disabled={loadingData} />
                                    <Button text="Edit Licence" intent="primary" onClick={onEditLicence} disabled={loadingData} />
                                </div>
                                <Tabs tabs={tabs} onClick={onTabSelected} disabled={loadingData} />

                                <div id="tab-operating-licence-details">

                                    <Collapsable title={currentLicence.licenceArea?.name} fullWidth expanded={showLicenceInfo} onClick={onHeaderToggle} loading={loadingData}>
                                        <div className="collapsable-content">
                                            <ShowHide
                                                evaluator={currentLicence.earnedRecognitionOperator === true}
                                                hide={(
                                                    <>
                                                        <div className="inline-items inline-items-space spacer-bottom-small">
                                                            <FormHeading headingLevel="h2" loading={loadingData}>OCRS</FormHeading>
                                                            <div className="button-row">
                                                                <Button icon="properties" iconOnly large={false} minimal onClick={() => setShowOcrsKey(true)} loading={loadingData} />
                                                                <Button icon="edit" iconOnly large={false} minimal onClick={() => setShowOcrsEdit(true)} loading={loadingData} />
                                                            </div>
                                                        </div>
                                                        <GridLayout numberOfColumns={4} className="spacer-bottom">
                                                            <div className={classNames({ 'bp3-skeleton': loadingData })}>
                                                                <div className="ol-stat-header">Road Worthiness Score</div>
                                                                <div className="ol-stat-large">{calculateOcrsBand(currentLicence.ocrs?.roadScore, 'ROAD')}</div>
                                                            </div>
                                                            <div className={classNames({ 'bp3-skeleton': loadingData })}>
                                                                <div className="ol-stat-header">Traffic Score</div>
                                                                <div className="ol-stat-large">{calculateOcrsBand(currentLicence.ocrs?.trafficScore, 'TRAFFIC')}</div>
                                                            </div>
                                                            <div className={classNames({ 'bp3-skeleton': loadingData })}>
                                                                <div className="ol-stat-header">Combined Score</div>
                                                                <div className="ol-stat-large">{calculateOcrsBand(currentLicence.ocrs?.combinedScore, 'COMBINED')}</div>
                                                            </div>
                                                        </GridLayout>
                                                    </>
                                                )}
                                            />

                                            <FormHeading headingLevel="h2" loading={loadingData}>Licence Information</FormHeading>
                                            <GridLayout numberOfColumns={4} className="spacer-bottom">
                                                <div className={classNames({ 'bp3-skeleton': loadingData })}>
                                                    <div className="ol-stat-header">Licence Area</div>
                                                    <div className="ol-stat">{currentLicence.licenceArea?.name}</div>
                                                </div>
                                                <div className={classNames({ 'bp3-skeleton': loadingData })}>
                                                    <div className="ol-stat-header">Licence Type</div>
                                                    <div className="ol-stat">{currentLicence.licenceType?.name}</div>
                                                </div>
                                                <div className={classNames({ 'bp3-skeleton': loadingData })}>
                                                    <div className="ol-stat-header">Licence Number</div>
                                                    <div className="ol-stat">********{currentLicence.licenceNumber}</div>
                                                </div>
                                                <div className={classNames({ 'bp3-skeleton': loadingData })}>
                                                    <div className="ol-stat-header">Licence Renewal Date</div>
                                                    <div className="ol-stat"><DateToLocal format={"DD MMM YYYY"}>{currentLicence.licenceRenewal}</DateToLocal></div>
                                                </div>
                                                <div className={classNames({ 'bp3-skeleton': loadingData })}>
                                                    <div className="ol-stat-header">No. of Operating Centres</div>
                                                    <div className="ol-stat"><Numeric>{currentListing?.operatingCentres}</Numeric></div>
                                                </div>
                                                <div className={classNames({ 'bp3-skeleton': loadingData })}>
                                                    <div className="ol-stat-header">Authorised Vehicles</div>
                                                    <div className="ol-stat"><Numeric>{currentListing?.totalVehicles}</Numeric></div>
                                                </div>
                                                <div className={classNames({ 'bp3-skeleton': loadingData })}>
                                                    <div className="ol-stat-header">Authorised Trailers</div>
                                                    <div className="ol-stat"><Numeric>{currentListing?.totalTrailers}</Numeric></div>
                                                </div>
                                                <ShowHide
                                                    evaluator={currentLicence.earnedRecognitionOperator === true}
                                                    show={(
                                                        <div className={classNames({ 'bp3-skeleton': loadingData })}>
                                                            <div className="ol-stat-header">Earned Recognition Number</div>
                                                            <div className="ol-stat">{currentLicence.earnedRecognitionNumber ?? "-"}</div>
                                                        </div>
                                                    )}
                                                />

                                            </GridLayout>
                                            <div className={classNames({ 'bp3-skeleton': loadingData })}>
                                                <div className="ol-stat-header">Traffic Commisioner's office</div>
                                                <div className="ol-stat">
                                                    <div className="inline-items">
                                                        <Icon icon="map-marker" />
                                                        <span>{currentTrafficCommisioner?.address}</span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </Collapsable>

                                </div>

                                <Modal
                                    isOpen={showOcrsKey}
                                    title="OCRS Banding Key"
                                    onClose={() => setShowOcrsKey(false)}
                                >
                                    <div className="spacer-bottom">
                                        <Table
                                            headerData={{
                                                headers: [{ key: "band", value: "OCRS Band" }, { key: "road", value: "Road Worthiness" }, { key: "traffic", value: "Traffic" }, { key: "combined", value: "Combined" }]
                                            }}
                                            data={[
                                                { cells: [<span className="ocrs-green" key="ocrs-green">Green</span>, "10 defect points or below", "5 offence points or below", "10 points or below"], key: "Green" },
                                                { cells: [<span className="ocrs-amber" key="ocrs-amber">Amber</span>, "10.01 to 25 defect points", "5.01 to 30 offence points", "10.01 to 25 points"], key: "Amber" },
                                                { cells: [<span className="ocrs-red" key="ocrs-red">Red</span>, "25.01 defect points or more", "30.01 offence points or more", "25.01 points or more"], key: "Red" },
                                                { cells: [<span className="ocrs-grey" key="ocrs-grey">Grey</span>, "No score", "No score", "No score"], key: "Grey" }
                                            ]}
                                            loadingData={false}
                                        />
                                    </div>
                                    <p>It is possible to have a score for one category but not for another. You are classified as a 'grey' operator if you have not had an interaction with DVSA and have no score in any category.</p>
                                </Modal>

                                <AlertConfirm
                                    isOpen={showOcrsEdit}
                                    title="OCRS"
                                    onCancel={onOcrsEditModalCancel}
                                    showCloseButton
                                    confirmButtonText="Save and close"
                                    confirmButtonIntent="primary"
                                    onConfirm={onSaveOcrsScores}
                                    disabled={savingOcrsScores || ocrsScoresInvalid}
                                >
                                    <GridLayout numberOfColumns={3}>
                                        <FormNumericInput min={0} max={9999} allowDecimal decimalPlaces={2} headingText="Road Worthiness Score" selectedValue={editLicenceModel?.ocrsRoadScore} onValueChange={(newValue) => onOcrsScoreUpdate(newValue, "ocrsRoadScore")} disabled={savingOcrsScores} />
                                        <FormNumericInput min={0} max={9999} allowDecimal decimalPlaces={2} headingText="Traffic Score" selectedValue={editLicenceModel?.ocrsTrafficScore} onValueChange={(newValue) => onOcrsScoreUpdate(newValue, "ocrsTrafficScore")} disabled={savingOcrsScores} />
                                        <FormNumericInput min={0} max={9999} allowDecimal decimalPlaces={2} headingText="Total Score" selectedValue={editLicenceModel?.ocrsCombinedScore} onValueChange={(newValue) => onOcrsScoreUpdate(newValue, "ocrsCombinedScore")} disabled={savingOcrsScores} />
                                    </GridLayout>


                                </AlertConfirm>

                                <AlertUnsavedData
                                    isDirty={isLicenceDataDirty}
                                    isDirtySamePage={blockModalClose}
                                    onCancelLeave={() => setBlockModalClose(false)}
                                    onConfirmLeave={() => onOcrsEditModalCancel(true)}
                                />
                            </div>
                        )}
                        show={(
                            <div>
                                <NotificationInline
                                    allowClose={false}
                                    show
                                    text="You do not have permission to view this licence"
                                    intent="danger">
                                </NotificationInline>
                                <div>
                                    <LinkButton intent="primary" text="Back to list view" href="/operating-licences?redirect=0" id="return-licence-listing" />
                                </div>
                            </div>
                        )}
                    />




                </div>
            )}
            no={() => <PageRestricted />}
        />

    );
}
