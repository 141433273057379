import React, { useState, useEffect, useCallback } from 'react';
import { Button as BPButton, MenuItem, Position } from "@blueprintjs/core";
import { Select } from "@blueprintjs/select";
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { FormHelperText } from "components/form-fields";
import 'components/form-fields/FormFields.css';
import { ShowHide } from 'components/layout';
import { Tooltip } from 'components/tooltip';
import { Button } from 'components/buttons';

export function FormSelect(props) {

	const [selectedItem, setSelectedItem] = useState(null);

	useEffect(setItemFromValue, [props.selectedValue, props.items]);

	function setItemFromValue() {
		var newItem = props.items.find(obj => obj.id === props.selectedValue);
		setSelectedItem(newItem);
	}

	const renderSelect = useCallback(() => {

		function renderSelectItem(item, { handleClick, modifiers }) {

			return (
				<MenuItem
					active={modifiers.active}
					onClick={handleClick}
					key={item.id}
					text={item.name}
					disabled={item.disabled || modifiers.disabled}
				/>
			);
		}

		return <Select
				className={classNames({ "bp3-skeleton": props.loading })}
				items={props.items}
				onItemSelect={props.onItemSelect}
				itemRenderer={renderSelectItem}
				filterable={false}
				popoverProps={{ minimal: true, position: Position.BOTTOM_LEFT, boundary: "window", usePortal: false }}
				activeItem={selectedItem}
				disabled={props.disabled}
			><BPButton text={selectedItem ? selectedItem.name : props.placeholder} disabled={props.disabled} rightIcon="double-caret-vertical" className={classNames({
				"fixed-width": !props.dynamicWidth,
				"bp3-skeleton": props.loading
			})} />
			</Select>;
	}, [props.items, props.onItemSelect, selectedItem, props.placeholder, props.loading, props.disabled, props.dynamicWidth]);

	function selectPrevItem() {
		var currentIndex = props.items.findIndex(obj => obj.id === selectedItem.id);
		currentIndex--;

		if (currentIndex < 0) {
			currentIndex = props.items.length - 1;
		}

		setSelectedItem(props.items[currentIndex]);
		props.onItemSelect(props.items[currentIndex]);
	}

	function selectNextItem() {
		var currentIndex = props.items.findIndex(obj => obj.id === selectedItem.id);
		currentIndex++;

		if (currentIndex > props.items.length - 1) {
			currentIndex = 0;
		}

		setSelectedItem(props.items[currentIndex]);
		props.onItemSelect(props.items[currentIndex]);
	}

	return (
		<div className="form-field form-field-select" id={props.id}>
			<ShowHide
				evaluator={props.headingText.length > 0}
				show={(
					<h4 className={classNames({ "bp3-skeleton": props.loading })}>{props.headingText}</h4>
				)}
			>
			</ShowHide>
			
			<FormHelperText loading={props.loading}>{props.helperText}</FormHelperText>

			<ShowHide
				evaluator={props.scrollable}
				show={(
					<div className="inline-items">
						<Tooltip content="Previous" >
							<Button minimal icon="fast-backward" intent="secondary" iconOnly disabled={props.items.length === 0 || selectedItem == null || props.disabled} loading={props.loading} onClick={selectPrevItem} />
						</Tooltip>
						{renderSelect()}
						<Tooltip content="Next" >
							<Button minimal icon="fast-forward" intent="secondary" iconOnly disabled={props.items.length === 0 || selectedItem == null || props.disabled} loading={props.loading} onClick={selectNextItem} />
						</Tooltip>
                    </div>
				)}
				hide={renderSelect()}
			/>
			
			<FormHelperText loading={props.loading} danger={true}>{props.dangerHelperText}</FormHelperText>
		</div>
	);
}

FormSelect.defaultProps = {
	id: null,
	placeholder: "Choose an item",
	items: [],
	loading: false,
	headingText: "",
	helperText: null,
	disabled: false,
	selectedValue: null,
	dangerHelperText: null,
	scrollable: false
};

FormSelect.propTypes = {
    id: PropTypes.string,
	placeholder: PropTypes.string,
	items: PropTypes.array,
	onItemSelect: PropTypes.func.isRequired,
	dynamicWidth: PropTypes.bool,
	loading: PropTypes.bool,
	headingText: PropTypes.string,
	helperText: PropTypes.node,
	disabled: PropTypes.bool,
	selectedValue: PropTypes.any,
	dangerHelperText: PropTypes.node,
	scrollable: PropTypes.bool
};