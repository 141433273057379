import React, { useState, useEffect, Fragment } from 'react';
import { PageRestricted } from 'pages/errors/page-restricted';
import { UserAccess, UserHasAccess } from 'components/user-access';
import { GridLayout } from 'components/grid';
import { FormTextInput, FormSelect, FormDateInput, FormNumericInput } from 'components/form-fields';
import { AlertUnsavedData, NotificationToaster } from 'components/notifications';
import { Button, ButtonSave } from 'components/buttons';
import { useDirtyData } from 'hooks/useDirtyData';
import { ShowHide, Collapsable } from 'components/layout';
import { HelperFunctions } from 'helpers';
import { useValidation } from "hooks/useValidation";
import { MoveAsset } from '../move-asset';
import { Intent } from '@blueprintjs/core';
import moment from "moment";
import PropTypes from 'prop-types';


export function Profile(props) {
    const requiredAction = ["Fleet:View", "FleetPCN:View", "FleetVIS:View"];
    const requiredEditActions = ["Fleet:Edit", "FleetPCN:Edit", "FleetVIS:Edit"];
    const requiredEditAccessToMoveLocations = ["Fleet:Edit", "FleetVIS:Edit"];
    const internalSuper = ["Internal:Super"];
    const canAccessPage = UserHasAccess(requiredAction);
    const canEditProfile = UserHasAccess(requiredEditActions);
    const canEditRegistrationField = UserHasAccess(internalSuper);
    const canEditMoveLocations = UserHasAccess(requiredEditAccessToMoveLocations);
    const dateFormat = "DD-MM-YYYY";
    const dateTimeFormat = "DD-MM-YYYY HH:mm";
    const assetStatusInactiveId = 2;
    const defaultAssetTypeId = 10;

    const [profile, setProfile] = useState({});
    const [vuExpanded, setVuExpanded] = useState(true);
    const [assetType, setAssetType] = useState({});
    const [displayAssetType, setDisplayAssetType] = useState({});
    const [displayMakes, setDisplayMakes] = useState([]);
    const [displayModels, setDisplayModels] = useState([]);
    const isDirty = useDirtyData(props.assetProfile, profile);
    const [isValid, errors, validate] = useValidation();
    const [isMoveAssetModalVisible, setIsMoveAssetModalVisible] = useState(false);

    function getRules() {
        return [];
    }

    useEffect(() => {
        if (!isDirty) {
            return;
        }
        const rules = getRules();
        let externalRule = [];

        if (profile.assetLocation?.assetStatus?.id === assetStatusInactiveId) {
            if (profile.assetLocation?.startDate && moment(profile.assetLocation?.startDate).isAfter(moment().add(-1, 'd').toDate())) {
                externalRule.push({
                    fieldName: "startDate",
                    valid: false,
                    errorMessage: 'If the asset is inactive the start date must be in the past'
                });
            }

            if (!profile.assetLocation?.endDate) {
                externalRule.push({
                    fieldName: "endDate",
                    valid: false,
                    errorMessage: 'The end date must be set if the asset is inactive'
                });
            } else if (profile.assetLocation?.endDate && profile.assetLocation?.startDate && moment(profile.assetLocation?.endDate).isBefore(moment(profile.assetLocation?.startDate))) {
                externalRule.push({
                    fieldName: "endDate",
                    valid: false,
                    errorMessage: 'The end date must be after the start date'
                });
            } else if (profile.assetLocation?.endDate && moment(profile.assetLocation?.endDate).isAfter(moment().add(-1, 'd').toDate())) {
                externalRule.push({
                    fieldName: "endDate",
                    valid: false,
                    errorMessage: 'If the asset is inactive the end date must be in the past'
                });
            }
        }

        validate(rules, profile, externalRule);
    }, [profile, isDirty]);

    useEffect(() => {
        if (!canAccessPage || !props.assetProfile
            || !props.assetOptions || !props.assetOptions.assetTypes || props.assetOptions.assetTypes.length < 1
            || !props.assetTypeDisplay || props.assetTypeDisplay.length < 1
        ) {
            return;
        }

        setProfile(HelperFunctions.deepClone(props.assetProfile));
        findAndSetAssetTypes(props.assetProfile.assetType?.id);
        findAndSetMakeModel(props.assetProfile.assetType?.id, props.assetProfile.make?.id);
    }, [props.assetProfile, props.assetTypeDisplay, props.assetOptions]);


    function findAndSetAssetTypes(assetTypeId) {
        if (!assetTypeId) {
            assetTypeId = defaultAssetTypeId;
        }
        //This needs to be set to assetTypeId when we impliment all the types
        setDisplayAssetType(props.assetTypeDisplay.find(a => a.assetTypeId === assetTypeId));
        setAssetType(props.assetOptions.assetTypes.find(a => a.id === assetTypeId));
    }

    function findAndSetMakeModel(assetTypeId, makeId) {
        let makeModelsForAssetType = props.assetOptions.assetMakeModelHierarchies.filter(a => a.assetTypeID === assetTypeId);

        let makeIds = makeModelsForAssetType.map(a => a.assetMakeID);
        let uniqueMakelIds = makeIds.filter(function (value, index, array) {
            return array.indexOf(value) === index;
        });
        setDisplayMakes(props.assetOptions.makes.filter(m => uniqueMakelIds.includes(m.id)));
        if (makeId) {
            let modelIds = makeModelsForAssetType.filter(a => a.assetMakeID === makeId).map(a => a.assetModelID);
            let uniqueModelIds = modelIds.filter(function (value, index, array) {
                return array.indexOf(value) === index;
            });
            setDisplayModels(props.assetOptions.models.filter(m => uniqueModelIds.includes(m.id)));
        } else {
            setDisplayModels([]);
        }
    }

    function onAssetStatusChange(value) {
        setProfile((prev) => {
            var newInstance = {
                ...prev
            };
            if (!newInstance.assetLocation.assetStatus?.id) {
                newInstance.assetLocation.assetStatus = {};
            }
            newInstance.assetLocation.assetStatus.id = value.id;
            return newInstance;
        });
    }

    function onAssetOwnershipChange(value) {
        setProfile((prev) => {
            var newInstance = {
                ...prev
            };
            if (!newInstance.assetLocation.assetOwnership?.id) {
                newInstance.assetLocation.assetOwnership = {};
            }
            newInstance.assetLocation.assetOwnership.id = value.id;
            return newInstance;
        });
    }

    function onStartDateChange(value) {
        setProfile((prev) => {
            var newInstance = {
                ...prev
            };
            newInstance.assetLocation.startDate = value ? moment(value).format("YYYY-MM-DD") : null;
            return newInstance;
        });
    }

    function onEndDateChange(value) {
        setProfile((prev) => {
            var newInstance = {
                ...prev
            };
            newInstance.assetLocation.endDate = value ? moment(value).format("YYYY-MM-DD") : null;
            return newInstance;
        });
    }

    function onFleetNumberChange(event) {
        if (event.target.value === undefined) {
            return;
        }

        setProfile((prev) => {
            var newInstance = {
                ...prev
            };
            newInstance.assetLocation.fleetNumber = event.target.value;
            return newInstance;
        });
    }

    function onMotExpiryDateChange(value) {
        setProfile((prev) => {
            var newInstance = {
                ...prev
            };
            newInstance.motDate = value ? moment(value).format("YYYY-MM-DD") : null;
            return newInstance;
        });
    }

    function onTaxExpiryDateChange(value) {
        setProfile((prev) => {
            var newInstance = {
                ...prev
            };
            newInstance.taxDueDate = value ? moment(value).format("YYYY-MM-DD") : null;
            return newInstance;
        });
    }

    function onAssetTypeChange(value) {
        setProfile((prev) => {
            var newInstance = {
                ...prev
            };
            if (!newInstance.assetType) {
                newInstance.assetType = {};
            }
            newInstance.assetType.id = value.id;
            return newInstance;
        });
        findAndSetAssetTypes(value.id);
        findAndSetMakeModel(value.id, profile.make?.id);
    }

    function onInspectionFrequencyChange(value) {
        if (!Number.isInteger(value)) {
            //For some reason the control tries to set the value to an empty string if it's null
            return;
        }
        setProfile((prev) => {
            var newInstance = {
                ...prev
            };
            newInstance.inspectionFrequency = value;
            return newInstance;
        });
    }

    function onRegistrationChange(event) {
        if (event.target.value === undefined) {
            return;
        }

        setProfile((prev) => {
            var newInstance = {
                ...prev
            };
            newInstance.registration = event.target.value;
            return newInstance;
        });
    }

    function onTankNoChange(event) {
        if (event.target.value === undefined) {
            return;
        }

        setProfile((prev) => {
            var newInstance = {
                ...prev
            };
            newInstance.tankNo = event.target.value;
            return newInstance;
        });
    }

    function onSerialNumberChange(event) {
        if (event.target.value === undefined) {
            return;
        }

        setProfile((prev) => {
            var newInstance = {
                ...prev
            };
            newInstance.serialNumber = event.target.value;
            return newInstance;
        });
    }

    function onChassisNumberChange(event) {
        if (event.target.value === undefined) {
            return;
        }

        setProfile((prev) => {
            var newInstance = {
                ...prev
            };
            newInstance.chassisNumber = event.target.value;
            return newInstance;
        });
    }

    function onRegistrationDateChange(value) {
        setProfile((prev) => {
            var newInstance = {
                ...prev
            };
            newInstance.registrationDate = value ? moment(value).format("YYYY-MM-DD") : null;
            return newInstance;
        });
    }

    function onMakeChange(value) {
        setProfile((prev) => {
            var newInstance = {
                ...prev
            };
            if (!newInstance.make) {
                newInstance.make = {};
            }
            newInstance.make.id = value.id;
            return newInstance;
        });
        findAndSetMakeModel(assetType.id, value.id);
    }

    function onYearOfManufactureChange(value) {
        if (!Number.isInteger(value)) {
            return;
        }
        setProfile((prev) => {
            var newInstance = {
                ...prev
            };
            newInstance.yearOfManufacture = value;
            return newInstance;
        });
    }

    function onModelChange(value) {
        setProfile((prev) => {
            var newInstance = {
                ...prev
            };
            if (!newInstance.model) {
                newInstance.model = {};
            }
            newInstance.model.id = value.id;
            return newInstance;
        });
    }

    function onModelCodeChange(event) {
        if (event.target.value === undefined) {
            return;
        }

        setProfile((prev) => {
            var newInstance = {
                ...prev
            };
            newInstance.modelCode = event.target.value;
            return newInstance;
        });
    }

    function onBodyTypeChange(value) {
        setProfile((prev) => {
            var newInstance = {
                ...prev
            };
            if (!newInstance.bodyType) {
                newInstance.bodyType = {};
            }
            newInstance.bodyType.id = value.id;
            return newInstance;
        });
    }

    function onHoursReadingChange(value) {
        if (!Number.isInteger(value)) {
            return;
        }
        setProfile((prev) => {
            var newInstance = {
                ...prev
            };
            newInstance.hoursReading = value;
            return newInstance;
        });
    }

    function onPowerSourceChange(value) {
        setProfile((prev) => {
            var newInstance = {
                ...prev
            };
            if (!newInstance.powerSource) {
                newInstance.powerSource = {};
            }
            newInstance.powerSource.id = value.id;
            return newInstance;
        });
    }

    function onSeatingCapacityChange(value) {
        if (!Number.isInteger(value)) {
            return;
        }
        setProfile((prev) => {
            var newInstance = {
                ...prev
            };
            newInstance.seatingCapacity = value;
            return newInstance;
        });
    }

    function onNoOfCompartmentsChange(value) {
        if (!Number.isInteger(value)) {
            return;
        }
        setProfile((prev) => {
            var newInstance = {
                ...prev
            };
            newInstance.noofCompartments = value;
            return newInstance;
        });
    }

    function onCapacityChange(value) {
        if (!Number.isInteger(value)) {
            return;
        }
        setProfile((prev) => {
            var newInstance = {
                ...prev
            };
            newInstance.capacity = value;
            return newInstance;
        });
    }

    function onTankManufacturerChange(value) {
        setProfile((prev) => {
            var newInstance = {
                ...prev
            };
            if (!newInstance.tankManufacturer) {
                newInstance.tankManufacturer = {};
            }
            newInstance.tankManufacturer.id = value.id;
            return newInstance;
        });
    }

    function onDetrNumberChange(event) {
        if (event.target.value === undefined) {
            return;
        }

        setProfile((prev) => {
            var newInstance = {
                ...prev
            };
            newInstance.detrNumber = event.target.value;
            return newInstance;
        });
    }

    function onTestDiscExpiryChange(value) {
        setProfile((prev) => {
            var newInstance = {
                ...prev
            };
            newInstance.testDiscExpiry = value ? moment(value).format("YYYY-MM-DD") : null;
            return newInstance;
        });
    }

    function saveProfile() {
        if (!canEditProfile) {
            return;
        }
        props.saveProfile(profile);
    }

    function onVuInfoExpand() {
        setVuExpanded((current) => { return !current; });
    }

    function showMoveAssetModal() {
        if (canEditMoveLocations) {
            setIsMoveAssetModalVisible(true);
        }
        else {
            NotificationToaster.show(Intent.DANGER, "You do not have permission to move this asset");
        }
    }

    function hideMoveAssetModal() {
        setIsMoveAssetModalVisible(false);
    }

    function saveNewAssetLocation(newAssetLocation) {
        setProfile((prev) => {
            var newInstance = {
                ...prev
            };

            newInstance.assetLocation.location.id = newAssetLocation.id;
            newInstance.assetLocation.location.name = newAssetLocation.name;
            return newInstance;
        });

        saveProfile();
    }

    return (
        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">
                    <MoveAsset
                        profile={profile}
                        isVisible={isMoveAssetModalVisible}
                        closeModal={hideMoveAssetModal}
                        saveAssetLocation={saveNewAssetLocation}
                        isSaving={props.saving}
                    />

                    <h3>Details</h3>

                    <ShowHide
                        evaluator={!props.loading}
                        show={(
                            <>
                                <Collapsable
                                    loading={props.loading}
                                    title="Vehicle Unit Information"
                                    fullWidth={true}
                                    expanded={vuExpanded}
                                    onClick={onVuInfoExpand}
                                    lightMode={true}
                                >
                                    <GridLayout numberOfColumns={3} gap={0}>
                                        <ShowHide
                                            evaluator={displayAssetType.displayTypeOfEquipment}
                                            show={(
                                                <FormTextInput
                                                    id="type-of-equipment"
                                                    disabled={true}
                                                    headingText="Type of equipment"
                                                    value={profile.typeOfEquipment}
                                                ></FormTextInput>
                                            )}
                                        />
                                        <ShowHide
                                            evaluator={displayAssetType.displayLastUploadDate}
                                            show={(
                                                <FormTextInput
                                                    id="last-upload-date"
                                                    disabled={true}
                                                    headingText="Last upload date"
                                                    value={HelperFunctions.setDateToLocal(profile.lastUploadDate, dateTimeFormat)}
                                                ></FormTextInput>
                                            )}
                                        />
                                        <ShowHide
                                            evaluator={displayAssetType.displayLastDownloadDate}
                                            show={(
                                                <FormTextInput
                                                    id="last-download-date"
                                                    disabled={true}
                                                    headingText="Last download date"
                                                    value={HelperFunctions.setDateToLocal(profile.lastDownloadDate, dateTimeFormat)}
                                                ></FormTextInput>
                                            )}
                                        />
                                        <FormTextInput
                                            id="odo-reading"
                                            disabled={true}
                                            headingText="Odometer reading"
                                            value={profile.odometerReading}
                                        ></FormTextInput>
                                        <FormTextInput
                                            id="vehicle-identification-number"
                                            disabled={true}
                                            headingText="Vehicle identification number"
                                            value={profile.vehicleIdentificationNumber}
                                        ></FormTextInput>
                                        <FormTextInput
                                            id="authorised-speed"
                                            disabled={true}
                                            headingText="Authorised speed"
                                            value={profile.authorisedSpeed}
                                        ></FormTextInput>
                                        <ShowHide
                                            evaluator={displayAssetType.displayVuManufacturer}
                                            show={(
                                                <FormTextInput
                                                    id="vu-manufacturer"
                                                    disabled={true}
                                                    headingText="Tachograph manufacturer"
                                                    value={profile.vuManufacturer}
                                                ></FormTextInput>
                                            )}
                                        />
                                        <ShowHide
                                            evaluator={displayAssetType.displayDigitalTachoCalibrationDate}
                                            show={(
                                                <FormTextInput
                                                    id="digital-tacho-calibration-date"
                                                    disabled={true}
                                                    headingText="Digital tachograph calibration date"
                                                    value={HelperFunctions.setDateToLocal(profile.tachographCalibrationDate, dateFormat)}
                                                ></FormTextInput>
                                            )}
                                        />
                                        <ShowHide
                                            evaluator={displayAssetType.displayLastThoroughExaminationDate}
                                            show={(
                                                <FormTextInput
                                                    id="last-thorough-examination-date"
                                                    disabled={true}
                                                    headingText="Last thorough examination date"
                                                    value={HelperFunctions.setDateToLocal(profile.dateOfLastExamination, dateFormat)}
                                                ></FormTextInput>
                                            )}
                                        />
                                        <ShowHide
                                            evaluator={displayAssetType.displayNextThoroughExaminationDate}
                                            show={(
                                                <FormTextInput
                                                    id="next-thorough-examination-date"
                                                    disabled={true}
                                                    headingText="Next thorough examination date"
                                                    value={HelperFunctions.setDateToLocal(profile.dateOfNextExamination, dateFormat)}
                                                ></FormTextInput>
                                            )}
                                        />
                                        <ShowHide
                                            evaluator={displayAssetType.displayWorkingPressure}
                                            show={(
                                                <FormTextInput
                                                    id="working-pressure"
                                                    disabled={true}
                                                    headingText="Working pressure"
                                                    value={profile.workingPressure}
                                                ></FormTextInput>
                                            )}
                                        />
                                        <ShowHide
                                            evaluator={displayAssetType.displayTestPressure}
                                            show={(
                                                <FormTextInput
                                                    id="test-pressure"
                                                    disabled={true}
                                                    headingText="Test pressure"
                                                    value={profile.testPressure}
                                                ></FormTextInput>
                                            )}
                                        />
                                        <ShowHide
                                            evaluator={displayAssetType.displayPvValveLastTestOrChange}
                                            show={(
                                                <FormTextInput
                                                    id="pv-valve-last-test-or-change"
                                                    disabled={true}
                                                    headingText="PV valve last test or change date"
                                                    value={profile.pvValveLastTestOrChange}
                                                ></FormTextInput>
                                            )}
                                        />
                                        <ShowHide
                                            evaluator={displayAssetType.displayPvValveNextTestOrChange}
                                            show={(
                                                <FormTextInput
                                                    id="pv-valve-next-test-or-change"
                                                    disabled={true}
                                                    headingText="PV valve next test or change date"
                                                    value={profile.pvValveNextTestOrChange}
                                                ></FormTextInput>
                                            )}
                                        />
                                        <ShowHide
                                            evaluator={displayAssetType.displayPvValveNominalPressureSetting}
                                            show={(
                                                <FormTextInput
                                                    id="pv-valve-nominal-pressure-setting"
                                                    disabled={true}
                                                    headingText="PV valve nominal pressure setting"
                                                    value={profile.pvValveNominalPressureSetting}
                                                ></FormTextInput>
                                            )}
                                        />
                                        <ShowHide
                                            evaluator={displayAssetType.displayTestedToInitialCertificate}
                                            show={(
                                                <FormTextInput
                                                    id="tested-to-initial-certificate"
                                                    disabled={true}
                                                    headingText="Tested to initial certificate"
                                                    value={profile.testedToInitialCertificate}
                                                ></FormTextInput>
                                            )}
                                        />
                                        <ShowHide
                                            evaluator={displayAssetType.displayInitialCertificationDate}
                                            show={(
                                                <FormTextInput
                                                    id="initial-certification-date"
                                                    disabled={true}
                                                    headingText="Initial certification date"
                                                    value={profile.initialCertificationdate}
                                                ></FormTextInput>
                                            )}
                                        />
                                        <ShowHide
                                            evaluator={displayAssetType.displayNextCertificationDate}
                                            show={(
                                                <FormTextInput
                                                    id="next-certification-date"
                                                    disabled={true}
                                                    headingText="Next certification due date"
                                                    value={profile.nextCertificationdate}
                                                ></FormTextInput>
                                            )}
                                        />
                                        <ShowHide
                                            evaluator={displayAssetType.displayCertification}
                                            show={(
                                                <FormTextInput
                                                    id="certification"
                                                    disabled={true}
                                                    headingText="Certification"
                                                    value={profile.certification}
                                                ></FormTextInput>
                                            )}
                                        />
                                        <ShowHide
                                            evaluator={displayAssetType.displayTankPlateRequired}
                                            show={(
                                                <FormTextInput
                                                    id="tank-plate-required"
                                                    disabled={true}
                                                    headingText="Tank plate required"
                                                    value={profile.tankPlateRequired}
                                                ></FormTextInput>
                                            )}
                                        />
                                    </GridLayout>
                                </Collapsable>


                                <h3>Vision Admin</h3>

                                <GridLayout numberOfColumns={2} gap={0}>
                                    <FormSelect
                                        id="asset-status"
                                        headingText="Asset Status"
                                        items={props.assetOptions.assetStatuses}
                                        onItemSelect={onAssetStatusChange}
                                        selectedValue={profile.assetLocation?.assetStatus?.id}
                                        disabled={!canEditProfile || props.saving}
                                        dynamicWidth={true}
                                        loading={props.loading}
                                    />
                                    <FormSelect
                                        id="asset-ownership"
                                        headingText="Asset Ownership"
                                        items={props.assetOptions.assetOwnerships}
                                        onItemSelect={onAssetOwnershipChange}
                                        selectedValue={profile.assetLocation?.assetOwnership?.id}
                                        disabled={!canEditProfile || props.saving}
                                        dynamicWidth={true}
                                        loading={props.loading}
                                    />
                                    <div className="form-row">
                                        <FormTextInput
                                            id="asset-location"
                                            disabled={true}
                                            headingText="Location"
                                            value={profile.assetLocation?.location?.name}
                                            loading={props.loading}
                                        ></FormTextInput>
                                        <ShowHide
                                            evaluator={canEditMoveLocations}
                                            show={(<Button intent='primary' text="Move Asset" disabled={props.saving} large={false} onClick={showMoveAssetModal} loading={props.loading} />)}
                                        />
                                    </div>
                                    <FormTextInput
                                        id="operating-licence-area"
                                        disabled={true}
                                        headingText="Operator Licensing Area"
                                        value={profile.licensingArea?.name}
                                        loading={props.loading}
                                    ></FormTextInput>
                                    <FormDateInput
                                        id="asset-start-date"
                                        disabled={!canEditProfile || props.saving}
                                        headingText="Start Date"
                                        onChange={onStartDateChange}
                                        value={profile.assetLocation?.startDate}
                                        loading={props.loading}
                                        dangerHelperText={errors.startDate}
                                        minDate={moment("2000-01-01").toDate()}
                                        maxDate={profile.assetLocation?.endDate ? moment(profile.assetLocation?.endDate).toDate() : moment("2100-01-01").toDate()}
                                    ></FormDateInput>
                                    <FormDateInput
                                        id="asset-end-date"
                                        disabled={!canEditProfile || props.saving || (profile.assetLocation?.assetStatus && profile.assetLocation?.assetStatus?.id !== assetStatusInactiveId) || errors.startDate && errors.startDate.length > 0}
                                        headingText="End Date"
                                        onChange={onEndDateChange}
                                        value={profile.assetLocation?.endDate}
                                        loading={props.loading}
                                        dangerHelperText={errors.endDate}
                                        minDate={profile.assetLocation?.startDate ? moment(profile.assetLocation?.startDate).toDate() : moment("2000-01-01").toDate()}
                                        maxDate={profile.assetLocation?.startDate && moment(profile.assetLocation?.startDate).isAfter(moment().add(-1, 'd').toDate()) ? moment(profile.assetLocation?.startDate).toDate() : moment().add(-1, 'd').toDate()}
                                    ></FormDateInput>
                                    <FormSelect
                                        id="asset-type"
                                        headingText="Asset Type"
                                        items={props.assetOptions.assetTypes ? props.assetOptions.assetTypes.filter(a => a.publicAllowed) : []}
                                        onItemSelect={onAssetTypeChange}
                                        selectedValue={profile.assetType?.id}
                                        disabled={!canEditProfile || props.saving}
                                        dynamicWidth={true}
                                        loading={props.loading}
                                    />
                                    <FormTextInput
                                        id="fleet-number"
                                        disabled={!canEditProfile || props.saving}
                                        headingText="Fleet Number"
                                        value={profile.assetLocation?.fleetNumber}
                                        loading={props.loading}
                                        onChange={onFleetNumberChange}
                                        maxLength={255}
                                    ></FormTextInput>
                                    <ShowHide
                                        evaluator={displayAssetType.displayMotExpiryDate}
                                        show={(
                                            <FormDateInput
                                                id="mot-expiry-date"
                                                disabled={!canEditProfile || props.saving}
                                                headingText="MOT Expiry Date"
                                                onChange={onMotExpiryDateChange}
                                                value={profile.motDate}
                                                loading={props.loading}
                                                dangerHelperText={errors.motDate}
                                                minDate={moment("2000-01-01").toDate()}
                                                maxDate={moment("2100-01-01").toDate()}
                                            ></FormDateInput>
                                        )}
                                    />
                                    <ShowHide
                                        evaluator={displayAssetType.displayTaxExpiryDate}
                                        show={(
                                            <FormDateInput
                                                id="tax-expiry-date"
                                                disabled={!canEditProfile || props.saving}
                                                headingText="Tax Expiry Date"
                                                onChange={onTaxExpiryDateChange}
                                                value={profile.taxDueDate}
                                                loading={props.loading}
                                                dangerHelperText={errors.taxDueDate}
                                                minDate={moment("2000-01-01").toDate()}
                                                maxDate={moment("2100-01-01").toDate()}
                                            ></FormDateInput>
                                        )}
                                    />
                                    <ShowHide
                                        evaluator={displayAssetType.displayInspectionFrequency}
                                        show={(
                                            <FormNumericInput
                                                id="inspection-frequency"
                                                disabled={!canEditProfile || props.saving}
                                                disabledInput={!canEditProfile || props.saving}
                                                headingText="Safety Inspection Frequency (Weeks)"
                                                onValueChange={onInspectionFrequencyChange}
                                                selectedValue={profile.inspectionFrequency}
                                                loading={props.loading}
                                                min={0}
                                                max={1000}
                                            ></FormNumericInput>
                                        )}
                                    />
                                </GridLayout>

                                <h3>Asset Information</h3>

                                <GridLayout numberOfColumns={2} gap={0}>
                                    <ShowHide
                                        evaluator={displayAssetType.displayRegistration}
                                        show={(
                                            <FormTextInput
                                                id="registration"
                                                disabled={!canEditProfile || props.saving || !canEditRegistrationField}
                                                headingText="Registration"
                                                value={profile.registration}
                                                loading={props.loading}
                                                onChange={onRegistrationChange}
                                            ></FormTextInput>
                                        )}
                                    />
                                    <ShowHide
                                        evaluator={displayAssetType.displayTankNumber}
                                        show={(
                                            <FormTextInput
                                                id="tank-no"
                                                disabled={!canEditProfile || props.saving || (assetType?.registration === "Tank" && !canEditRegistrationField)}
                                                headingText="Tank Number"
                                                value={profile.tankNo}
                                                loading={props.loading}
                                                onChange={onTankNoChange}
                                            ></FormTextInput>
                                        )}
                                    />
                                    <ShowHide
                                        evaluator={displayAssetType.displaySerialNumber}
                                        show={(
                                            <FormTextInput
                                                id="serial-no"
                                                disabled={!canEditProfile || props.saving || (assetType?.registration === "Serial" && !canEditRegistrationField)}
                                                headingText="Serial Number"
                                                value={profile.serialNumber}
                                                loading={props.loading}
                                                onChange={onSerialNumberChange}
                                            ></FormTextInput>
                                        )}
                                    />
                                    <FormTextInput
                                        id="chassis-number"
                                        disabled={!canEditProfile || props.saving || (assetType?.registration === "Chassis" && !canEditRegistrationField)}
                                        headingText="Chassis Number"
                                        value={profile.chassisNumber}
                                        loading={props.loading}
                                        onChange={onChassisNumberChange}
                                    ></FormTextInput>
                                    <ShowHide
                                        evaluator={displayAssetType.displayDateOfRegistration}
                                        show={(
                                            <FormDateInput
                                                id="registration-date"
                                                disabled={!canEditProfile || props.saving}
                                                headingText="Registration Date"
                                                onChange={onRegistrationDateChange}
                                                value={profile.registrationDate}
                                                loading={props.loading}
                                                dangerHelperText={errors.RegistrationDate}
                                                minDate={moment("1900-01-01").toDate()}
                                                maxDate={moment().toDate()}
                                            ></FormDateInput>
                                        )}
                                    />
                                    <FormSelect
                                        id="make"
                                        headingText="Manufacturer"
                                        items={displayMakes}
                                        onItemSelect={onMakeChange}
                                        selectedValue={profile.make?.id}
                                        disabled={!canEditProfile || props.saving || !displayMakes || displayMakes.length < 1}
                                        dynamicWidth={true}
                                        loading={props.loading}
                                    />
                                    <ShowHide
                                        evaluator={displayAssetType.displayYearOfManufacture}
                                        show={(
                                            <FormNumericInput
                                                id="year-of-manufacture"
                                                disabled={!canEditProfile || props.saving}
                                                disabledInput={!canEditProfile || props.saving}
                                                headingText="Year Of Manufacture"
                                                onValueChange={onYearOfManufactureChange}
                                                selectedValue={profile.yearOfManufacture}
                                                loading={props.loading}
                                                min={1950}
                                                max={moment().year()}
                                            ></FormNumericInput>
                                        )}
                                    />
                                    <FormSelect
                                        id="model"
                                        headingText="Model"
                                        items={displayModels}
                                        onItemSelect={onModelChange}
                                        selectedValue={profile.model?.id}
                                        disabled={!canEditProfile || props.saving || !displayModels || displayModels.length < 1}
                                        dynamicWidth={true}
                                        loading={props.loading}
                                    />
                                    <FormTextInput
                                        id="model-code"
                                        disabled={!canEditProfile || props.saving}
                                        headingText="Model Code"
                                        value={profile.modelCode}
                                        loading={props.loading}
                                        onChange={onModelCodeChange}
                                    ></FormTextInput>
                                    <FormSelect
                                        id="body-type"
                                        headingText="Body Type"
                                        items={props.assetOptions.bodyTypes}
                                        onItemSelect={onBodyTypeChange}
                                        selectedValue={profile.bodyType?.id}
                                        disabled={!canEditProfile || props.saving}
                                        dynamicWidth={true}
                                        loading={props.loading}
                                    />
                                    <ShowHide
                                        evaluator={displayAssetType.displayHoursReading}
                                        show={(
                                            <FormNumericInput
                                                id="hours-reading"
                                                disabled={!canEditProfile || props.saving}
                                                disabledInput={!canEditProfile || props.saving}
                                                headingText="Hours Reading"
                                                onValueChange={onHoursReadingChange}
                                                selectedValue={profile.hoursReading}
                                                loading={props.loading}
                                                min={0}
                                                max={1000000}
                                            ></FormNumericInput>
                                        )}
                                    />
                                    <ShowHide
                                        evaluator={displayAssetType.displayPowerSource}
                                        show={(
                                            <FormSelect
                                                id="power-source"
                                                headingText="Power Source"
                                                items={props.assetOptions.powerSources}
                                                onItemSelect={onPowerSourceChange}
                                                selectedValue={profile.powerSource?.id}
                                                disabled={!canEditProfile || props.saving}
                                                dynamicWidth={true}
                                                loading={props.loading}
                                            />
                                        )}
                                    />
                                    <ShowHide
                                        evaluator={displayAssetType.displaySeatingCapacity}
                                        show={(
                                            <FormNumericInput
                                                id="seating-capacity"
                                                disabled={!canEditProfile || props.saving}
                                                disabledInput={!canEditProfile || props.saving}
                                                headingText="Seating Capacity"
                                                onValueChange={onSeatingCapacityChange}
                                                selectedValue={profile.seatingCapacity}
                                                loading={props.loading}
                                                min={0}
                                                max={200}
                                            ></FormNumericInput>
                                        )}
                                    />
                                    <ShowHide
                                        evaluator={displayAssetType.displayNumberOfCompartments}
                                        show={(
                                            <FormNumericInput
                                                id="no-of-compartments"
                                                disabled={!canEditProfile || props.saving}
                                                disabledInput={!canEditProfile || props.saving}
                                                headingText="Number Of Compartments"
                                                onValueChange={onNoOfCompartmentsChange}
                                                selectedValue={profile.noofCompartments}
                                                loading={props.loading}
                                                min={0}
                                                max={50}
                                            ></FormNumericInput>
                                        )}
                                    />
                                    <ShowHide
                                        evaluator={displayAssetType.displayCapacity}
                                        show={(
                                            <FormNumericInput
                                                id="capacity"
                                                disabled={!canEditProfile || props.saving}
                                                disabledInput={!canEditProfile || props.saving}
                                                headingText="Capacity"
                                                onValueChange={onCapacityChange}
                                                selectedValue={profile.capacity}
                                                loading={props.loading}
                                                min={0}
                                                max={1000000}
                                            ></FormNumericInput>
                                        )}
                                    />
                                    <ShowHide
                                        evaluator={displayAssetType.displayTankManufacturer}
                                        show={(
                                            <FormSelect
                                                id="tank-manufacturer"
                                                headingText="Tank Manufacturer"
                                                items={props.assetOptions.tankManufacturers}
                                                onItemSelect={onTankManufacturerChange}
                                                selectedValue={profile.tankManufacturer?.id}
                                                disabled={!canEditProfile || props.saving}
                                                dynamicWidth={true}
                                                loading={props.loading}
                                            />
                                        )}
                                    />
                                    <ShowHide
                                        evaluator={displayAssetType.displayDetrNumber}
                                        show={(
                                            <FormTextInput
                                                id="detr-number"
                                                disabled={!canEditProfile || props.saving}
                                                headingText="DETR Number"
                                                value={profile.detrNumber}
                                                loading={props.loading}
                                                onChange={onDetrNumberChange}
                                            ></FormTextInput>
                                        )}
                                    />
                                    <ShowHide
                                        evaluator={displayAssetType.displayTestDiscExpiry}
                                        show={(
                                            <FormDateInput
                                                id="test-disc-expiry"
                                                disabled={!canEditProfile || props.saving}
                                                headingText="Test Disc Expiry Date"
                                                onChange={onTestDiscExpiryChange}
                                                value={profile.testDiscExpiry}
                                                loading={props.loading}
                                                minDate={moment("2000-01-01").toDate()}
                                                maxDate={moment("2100-01-01").toDate()}
                                            ></FormDateInput>
                                        )}
                                    />
                                </GridLayout>
                            </>
                        )}
                    />

                    <ShowHide
                        evaluator={canEditProfile}
                        show={(
                            <Fragment>
                                <AlertUnsavedData
                                    isDirty={isDirty}
                                    scrollToBottomOnCancel={true}
                                >
                                </AlertUnsavedData>

                                <ButtonSave text="Save"
                                    disabled={props.saving}
                                    simpleDisabled={!isDirty || !isValid}
                                    loading={props.loading}
                                    onClick={saveProfile}
                                />
                            </Fragment>
                        )}
                    />
                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );
}

Profile.defaultProps = {
    assetProfile: {},
    saveProfile: () => { },
    loading: true,
    saving: false,
    assetTypeDisplay: {},
    assetOptions: {}
};

Profile.propTypes = {
    assetProfile: PropTypes.object.isRequired,
    saveProfile: PropTypes.func.isRequired,
    assetTypeDisplay: PropTypes.array.isRequired,
    assetOptions: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired
};