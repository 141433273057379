import ApiService from "services/ApiService";
import ApiRequestService from "services/ApiRequestService";

const badRequestCode = 400;

export const AssetService = {

    getAssetOptions() {
        return ApiRequestService.get('asset', 'options/asset');
    },
    getActivityTypes() {
        return ApiRequestService.get('asset', 'options/activityTypes');
    },
    createAsset(asset) {
        return ApiRequestService.post('asset', 'assets', asset, "The asset could not be created, please try again.");
    },
    getMotTests(id) {
        return ApiRequestService.get('asset', `mots/asset/${id}`);
    },
    performMotCheck(id) {
        return ApiRequestService.put('asset', `mots/asset/${id}/Sync`);
    },
    getWalkaroundChecks(
        route,
        id,
        pageNumber,
        pageSize,
        searchTerm,
        sortField,
        sortDirection,
        startDate,
        endDate,
        filters
    ) {
        var queryFilters = "";
        filters.forEach(function (element, index) {
            queryFilters += `filters[${index}].Key=${encodeURIComponent(
                element.name
            )}&filters[${index}].Value=${encodeURIComponent(element.value)}&`;
        });

        return new Promise((resolve, reject) => {
            ApiService.get(
                `/asset/WalkaroundChecks/${route}/${id}?pageNumber=${pageNumber}&requestCount=${pageSize}&searchTerm=${encodeURIComponent(
                    searchTerm
                )}&sortField=${encodeURI(
                    sortField
                )}&sortDirection=${sortDirection}&${queryFilters}&startDate=${startDate}&endDate=${endDate}`
            ).then(
                (response) => {
                    resolve(response.data);
                },
                (error) => {
                    if (error.response.status === badRequestCode) {
                        reject(error.response);
                    } else {
                        reject(
                            "Unable to search walkaround checks. Please try again later."
                        );
                    }
                }
            );
        });
    },
    getAllWalkaroundChecks(searchObject) {
        return new Promise((resolve, reject) => {
            ApiService.post(
                `/asset/WalkaroundChecks/search`, searchObject).then(
                    (response) => {
                        resolve(response.data);
                    },
                    (error) => {
                        if (error.response.status === badRequestCode) {
                            reject(error.response);
                        } else {
                            reject(
                                "Unable to search walkaround checks. Please try again later."
                            );
                        }
                    }
                );
        });
    },
    getWalkaroundCheck(id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/asset/WalkaroundChecks/${id}`).then(
                (response) => {
                    resolve(response.data);
                },
                (error) => {
                    reject(error.response);
                }
            );
        });
    },
    signOffWalkaroundCheck(id) {


        return new Promise((resolve, reject) => {
            ApiService.patch(
                `/asset/WalkaroundChecks/${id}/SignOff`).then(
                    (response) => {
                        resolve(response.data);
                    },
                    (error) => {
                        if (error.response && error.response.status === badRequestCode) {
                            reject(error.response.data);
                        } else {
                            reject("Unable to sign off walkaround check, please try again.");
                        }
                    }
                );
        });
    },
    signOffWalkaroundDefect(id, defectId, defect) {
        return new Promise((resolve, reject) => {
            ApiService.patch(
                `/asset/WalkaroundChecks/${id}/defect/${defectId}/SignOff`,
                {
                    ...defect,
                    comment: defect.rectifiedComment
                }
            ).then(
                (response) => {
                    resolve(response.data);
                },
                (error) => {
                    if (error.response && error.response.status === badRequestCode) {
                        reject(error.response.data);
                    } else {
                        reject("Unable to sign off defect, please try again.");
                    }
                }
            );
        });
    },
    internalSearchAssets(pageSize, pageNumber, searchTerm) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/asset/Search?requestCount=${pageSize}&pageNumber=${pageNumber}&searchTerm=${searchTerm}`)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function () {
                    reject("Unable to search assets. Please try again.");
                });
        });
    },
    getAsset(assetId) {
        return ApiRequestService.get('asset', `assets/${assetId}`);
    },
    searchAssets(data) {
        return new Promise((resolve, reject) => {
            ApiService.post('/asset/Assets/Search', data).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                });
        });
    },
    searchAssetsWithPreferences(data) {
        return new Promise((resolve, reject) => {
            ApiService.post('/asset/Assets/PreferenceSearch', data).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                });
        });
    },
    searchTachoDataWithAssetIds(assetIds, startDate, weeksToReturn) {
        return new Promise((resolve, reject) => {
            ApiService.post('/asset/TachoData/Summary', { startDate: startDate, weeksToReturn: weeksToReturn, assets: assetIds }).then(
                (result) => {
                    resolve(result.data);
                },
                (error) => {
                    reject(error);
                });
        });
    },
    bulkMoveAssetsForLocation(settings) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/asset/BulkMove/Location`, settings)
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },
    bulkMoveAssetsForAccount(settings) {
        return new Promise((resolve, reject) => {
            ApiService.post(`/asset/BulkMove/Account`, settings)
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },
    getBulkMoveChangeLogForLocation(locationId, pageSize, pageNumber, startDate, endDate) {
        return ApiRequestService.get('asset', `BulkMove/Location/Changelog/${locationId}?resultCount=${pageSize}&pageNumber=${pageNumber}&startDate=${startDate}&endDate=${endDate}`);
    },
    crackVUCard(vuFile) {
        const config = {
            headers: {
                'content-Type': 'multipart/form-data'
            }
        };

        const formData = new FormData();
        formData.append('file', vuFile)

        return new Promise((resolve, reject) => {
            ApiService.post(`/asset/VehicleUnit/Contents`, formData, config)
                .then(function (response) {
                    resolve(response);
                })
                .catch(function (error) {
                    reject(error);
                });
        });
    },
    getAllJourneyTypesForAccount() {
        return new Promise((resolve, reject) => {
            ApiService.get('/asset/JourneyTypes').then(
                (response) => {
                    resolve(response.data);
                },
                (error) => {
                    reject(error.response);
                }
            );
        });
    },
    assignJourney(assetId, journeyTypeId, startDate, endDate) {
        return new Promise((resolve, reject) => {
            ApiService.patch(
                "/asset/CardIw/AssignJourney",
                {
                    journeyTypeId: journeyTypeId,
                    assetId: assetId,
                    startDate: startDate,
                    endDate: endDate
                }
            ).then(
                (response) => {
                    resolve(response.data);
                },
                (error) => {
                    if (error.response && error.response.status === badRequestCode) {
                        reject(error.response.data);
                    } else {
                        reject("Unable to assign a journey, please try again.");
                    }
                }
            );
        });
    },
    assignEmployee(assetId, employeeId, startDateTime, endDateTime) {
        const successCode = 204;
        return new Promise((resolve, reject) => {
            ApiService.patch(
                "/asset/CardIw/AssignEmployee",
                {
                    employeeId: employeeId,
                    assetId: assetId,
                    vuActivityStartTime: startDateTime,
                    vuActivityEndTime: endDateTime
                }
            ).then(
                (response) => {
                    if (response.status === successCode) {
                        resolve(response);
                    }
                    else {
                        reject("Unable to assign employee, please try again.");
                    }
                },
                (error) => {
                    if (error.response && error.response.status === badRequestCode) {
                        reject(error.response.data);
                    } else {
                        reject("Unable to assign employee, please try again.");
                    }                   
                }
            );
        });
    },
    deleteJourney(assetId, journeyTypeId, startDate, endDate) {
        return new Promise((resolve, reject) => {
            ApiService.delete(
                "/asset/CardIw/AssignJourney",
                {
                    data: {
                        journeyTypeId: journeyTypeId,
                        assetId: assetId,
                        startDate: startDate,
                        endDate: endDate
                    }
                }
            ).then(
                (response) => {
                    resolve(response.data);
                },
                (error) => {
                    if (error.response && error.response.status === badRequestCode) {
                        reject(error.response.data);
                    } else {
                        reject("Unable to assign a journey, please try again.");
                    }
                }
            );
        });
    },
    getTachoReports(reportPageName) {
        return ApiRequestService.get('asset', `reports/tacho/${reportPageName}`);
    },
    getTachoPreferences() {
        return ApiRequestService.get('asset', 'displayPreferences/Tacho');
    },
    requestTachoReport(id, pageName, searchParameters) {
        return ApiRequestService.post('asset', `reports/${pageName}/${id}/`, searchParameters);
    },
    saveTachoPreferences(preferences) {
        return ApiRequestService.put('asset', 'displayPreferences/Tacho', preferences);
    },
    getTachoDataForAsset(assetId, date) {
        return ApiRequestService.get('asset', `tachoData/Asset/${assetId}/date/${date}`);
    },
    getTachoDataAssetCalendar(assetId, startDate, daysToReturn) {
        return ApiRequestService.get('asset', `tachoData/Asset/${assetId}/date/${startDate}/days/${daysToReturn}`);
    },
    getDownloadLinkForVehicleUnitFile(uploadId) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/asset/vehicleUnit/Upload/${uploadId}`).then(
                (response) => {
                    resolve(response.data);
                },
                (error) => {
                    reject(error.response);
                }
            );
        });
    },
    getHarshBrakingDataForAsset(assetId, date) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/asset/harshBraking/Asset/${assetId}/date/${date}`).then(
                (response) => {
                    resolve(response.data);
                },
                (error) => {
                    reject(error.response);
                }
            );
        });
    },
    getSpeedDataForAsset(assetId, date) {
        return ApiRequestService.get('asset', `speedData/Asset/${assetId}/date/${date}`);
    },
    GetHoldingAreaCounts() {
        return ApiRequestService.get('asset', 'holdingArea');
    },
    getSafetyFeatures(assetId) {
        return new Promise((resolve, reject) => {
            ApiService.get(`/asset/Assets/${assetId}/SafetyFeatures`)
                .then(function (response) {
                    resolve(response.data);
                })
                .catch(function () {
                    reject("Unable to get asset safety features. Please try again.");
                });
        });
    },
    updateAssetSafetyFeatures(assetId, assetSafetyFeatures) {
        return new Promise((resolve, reject) => {
            ApiService.put(`/asset/Assets/${assetId}/SafetyFeatures`, assetSafetyFeatures)
                .then(function (response) {
                    resolve(response);
                })
                .catch(function () {
                    reject("Unable to save asset safety features. Please try again.");
                });
        });
    },
    getNote(assetId, date) {
        return ApiRequestService.get('asset', `notes/Asset/${assetId}/date/${date}`);
    }, 
    getUploads(assetId, date) {
        return ApiRequestService.get('asset', `assets/${assetId}/Uploads/Date/${date}`);
    }, 
    saveNote(assetId, dates, note) {
        return new Promise((resolve, reject) => {
            ApiService.put(
                `/asset/notes/Asset/${assetId}`,
                {
                    dates: dates,
                    note: note
                }
            ).then(
                (response) => {
                    resolve(response.data);
                },
                (error) => {
                    if (error.response && error.response.status === badRequestCode) {
                        reject(error.response.data);
                    } else {
                        reject("Unable to save note, please try again.");
                    }
                }
            );
        });
    },
    deleteNote(assetId, dates) {
        return new Promise((resolve, reject) => {
            ApiService.put(
                `/asset/notes/Asset/${assetId}/delete`, dates
            ).then(
                (response) => {
                    resolve(response.data);
                },
                (error) => {
                    if (error.response && error.response.status === badRequestCode) {
                        reject(error.response.data);
                    } else {
                        reject("Unable to delete note, please try again.");
                    }
                }
            );
        });
    },
    upsertComment(cardIwId, comment) {
        return new Promise((resolve, reject) => {
            ApiService.put(
                `/asset/CardIw/${cardIwId}/Comment`,
                {
                    comment: comment
                }
            ).then(
                (response) => {
                    resolve(response.data);
                },
                (error) => {
                    if (error.response && error.response.status === badRequestCode) {
                        reject(error.response.data);
                    } else {
                        reject("Unable to save comment, please try again.");
                    }
                }
            );
        });
    },
    deleteComment(cardIwId) {
        return new Promise((resolve, reject) => {
            ApiService.delete(
                `/asset/CardIw/${cardIwId}/Comment`
            ).then(
                (response) => {
                    resolve(response.data);
                },
                (error) => {
                    if (error.response && error.response.status === badRequestCode) {
                        reject(error.response.data);
                    } else {
                        reject("Unable to delete comment, please try again.");
                    }
                }
            );
        });
    },
    unassignDriverFromCardIw(assetId, startDateTime, endDatTime) {
        var payload = {
            assetId: assetId,
            VuCardIwStartTime: startDateTime,
            VuCardIwEndTime: endDatTime
        };

        return new Promise((resolve, reject) => {
            ApiService.patch(
                `/asset/CardIw/UnassignEmployee/`, payload
            ).then(
                (response) => {
                    resolve(response.data);
                },
                (error) => {
                    if (error.response && error.response.status === badRequestCode) {
                        reject(error.response.data);
                    } else {
                        reject("Unable to unassign driver, please try again.");
                    }
                }
            );
        });
    },
    getJourneyTypes() {
        return ApiRequestService.get('asset', "journeyTypes");
    },  
    unassignJourney(assetId, assignedJourneyId) {
        return new Promise((resolve, reject) => {
            ApiService.patch(
                "/asset/CardIw/AssignJourney/Unassign",
                {
                    assetId: assetId,
                    assignedJourneyId: assignedJourneyId
                }
            ).then(
                (response) => {
                    resolve(response.data);
                },
                (error) => {
                    if (error.response && error.response.status === badRequestCode) {
                        reject(error.response.data);
                    } else {
                        reject("Unable to unassign a journey, please try again.");
                    }
                }
            );
        });
    },
    getUploadSummary(assetId) {
        return ApiRequestService.get(`asset`, `assets/${assetId}/UploadSummary`)
    },
    getAuditTrailForAsset(assetId, date, pageNumber, pageSize) {
        return ApiRequestService.get('asset', `assets/trail/${assetId}/${date}?pageNumber=${pageNumber}&pageSize=${pageSize}`);
    },
    saveAssetProfile(assetProfile) {
        return ApiRequestService.put('asset', 'assets', assetProfile);
    },
    deleteAsset(assetId) {
        return new Promise((resolve, reject) => {
            ApiService.delete(
                `/asset/assets/${assetId}`
            ).then(
                (response) => {
                    resolve(response.data);
                },
                (error) => {
                    if (error.response && error.response.status === badRequestCode) {
                        reject(error.response.data);
                    } else {
                        reject("Unable to delete asset, please try again.");
                    }
                }
            );
        });
    }
};