import React, { useState, useEffect, Fragment, useRef } from 'react';
import { Link } from "react-router-dom";
import { useDebounce } from 'hooks/useDebounce';
import { StringToEllipsesString } from 'components/formatting';
import { FormCheckbox, FormDateRangeInput, FormLocationSelector, FormTextInput, FormTextArea, FormRadio, FormDateInput, FormSwitch } from 'components/form-fields';
import { Button } from 'components/buttons'
import { ListingTable } from "components/listing";
import { PageRestricted } from 'pages/errors/page-restricted';
import { UserAccess, UserHasAccess } from 'components/user-access';
import { JobService, LocationService } from 'services';
import { Collapsable, ShowHide } from "components/layout";
import classNames from "classnames";
import moment from "moment";
import { Tooltip } from 'components/tooltip';
import { Filter } from 'components/filtering';
import { useSelector, useDispatch } from 'react-redux';
import { SetFiltering } from 'state/actions';
import { DateToLocal } from "components/dates";
import { AlertConfirm, NotificationToaster, NotificationInline } from "components/notifications";
import { useValidation } from "hooks/useValidation";
import { Intent, Position } from '@blueprintjs/core';
import { StatusBlock } from "components/status";
import { Tabs } from 'components/navigation';
import './JobListing.css';
import axios from 'axios';
export function JobListing(props) {

    const defaultRecordSize = 25;
    const debounceTimeout = 750;

    const [totalRecords, setTotalRecords] = useState(0);
    const [tableRows, setTableRows] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(defaultRecordSize);
    const [contracts, setContracts] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const [savingData, setSavingData] = useState(false);
    const [locationSetUp, setLocationSetUp] = useState(false);
    const [dateSetUp, setDateSetUp] = useState(false);
    const [tableSortBy, setTableSortBy] = useState({ headerName: "Date", sortName: "COMPLETEDSCHEDULEDUEDDATE" });
    const [tableSortDir, setTableSortDir] = useState("D");
    const [showFilters, setShowFilters] = useState(false);
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [pageFilters, setPageFilters] = useState([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [showAcknowledge, setShowAcknowledge] = useState(false);
    const [showAmend, setShowAmend] = useState(false);
    const [currentJobId, setCurrentJobId] = useState(-1);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentTab, setCurrentTab] = useState(0);
    const debouncedSearchTerm = useDebounce(searchTerm, debounceTimeout);
    const [currentAdminMode, setCurrentAdminMode] = useState(null);
    const [isValid, errors, validate] = useValidation();
    const [acknowledgedOnBehalfOf, setAcknowledgedOnBehalfOf] = useState({
        acknowledgedOnBehalfOf: ""
    });
    const [amendmentDetails, setAmendmentDetails] = useState({
        reschedule: true,
        scheduleDate: null,
        phoneNumber: "",
        reasonForChange: ""
    });
    const [tableHeaders, setTableHeaders] = useState([]);
    const [locationList, setLocationList] = useState({ location: [], hierarchy: [] });
    const [actionVerb, setActionVerb] = useState("amend");
    const [actionNoun, setActionNoun] = useState("amendment");
    const [productName, setProductName] = useState("");
    const [location, setLocation] = useState("");
    const acknowledgedOnBehalfOfInputRef = useRef(null);
    const amendmentInputRef = useRef(null);
    const searchInputRef = useRef(null);
    const canAccessPage = UserHasAccess(props.requiredAction);
    const isInternal = UserHasAccess("Internal");
    const noDataMessage = "No visits found.";
    const tableHeadersJobAdmin = ["Reference", "Product Name", "Scheduled status", "Internal Id", "Asset Id", "Asset Type", "Visit Location", "Postcode", "Due Date", "Comp/Sch Date", "Duration", "Status", "Assignee", "FLS Status", "Action"];
    const tableHeadersUpcomingJobs = ["Reference", "Scheduled For", "Product Name", "Scheduled status", "Asset Id", "Asset Type", "Visit Location", "Duration", "Status", "Actions"];
    const sortableHeaders = [{ headerName: "Reference", sortName: "JOBID" },
    { headerName: "Product Description", sortName: "PRODUCTDESCRIPTION" },
    { headerName: "Product Name", sortName: "PRODUCTNAME" },
    { headerName: "Visit Location", sortName: "LOCATIONNAME" },
    { headerName: "Comp/Sch Date", sortName: "COMPLETEDSCHEDULEDDATE" },
    { headerName: "Scheduled For", sortName: "COMPLETEDSCHEDULEDDATE" },
    { headerName: "Internal Id", sortName: "PUBSJOBID" },
    { headerName: "Status", sortName: "STATE" },
    { headerName: "Due Date", sortName: "DUEDATE" }];
    const newState = "New";
    const cancelledState = "Cancelled";
    const waitingState = "Waiting";
    const pendingState = "Pending";
    const readyState = "Ready";
    const inProgressState = "In Progress";
    const doneState = "Done";
    const deemedDoneState = "Deemed Done";
    const overrideDeemedDoneState = "Override Deemed Done";
    const doneIncompleteState = "Done (Incomplete)";
    const failedAppointmentState = "Failed Appointment";
    const visBA = "VIS";
    const tachoBA = "Tacho";
    const auditBA = "Audit";
    const dateFilterReduxName = "jobListingDates";
    const jobLocationReduxName = "jobListingLocation";
    const jobSearchReduxName = "jobSearch";
    const rescheduleMaxDateDaysInFuture = 500;
    const productDescLength = 20;
    const thirtyMinutes = 30;

    const reduxDates = useSelector(state => state.filters[dateFilterReduxName]);
    const reduxLocations = useSelector(state => state.filters[jobLocationReduxName]);
    const reduxSearch = useSelector(state => state.filters[jobSearchReduxName]);
    const dispatch = useDispatch();

    const [selectedLocations, setSelectedLocations] = useState([]);
    const locationListOnFilterOpen = useRef(selectedLocations);
    const currentPageNumber = useRef();
    const currentPageSize = useRef();
    const currentSearchTerm = useRef();
    const currentTableSortBy = useRef();
    const currentTableSortDir = useRef();
    const currentFilters = useRef();
    const currentStartDate = useRef();
    const currentEndDate = useRef();
    const currentShowUnallocatedJobs = useRef();
    const [showUnallocatedJobs, setshowUnallocatedJobs] = useState(false);

    const [tabs, setTabs] = useState([
        { key: "STANDARDVIEW", title: "Standard View", selected: true },
        { key: "CONTRACTSVIEW", title: "Contracts View" },
    ]);

    useEffect(() => {
        setUpPage();
    }, []);

    useEffect(() => {
        validateAcknowledgedOnBehalfOf();
    }, [acknowledgedOnBehalfOf]);

    useEffect(() => {
        validateAmendmentDetails();
    }, [amendmentDetails]);

    useEffect(() => {
        //Should only happen if they switch straight from Job Admin to upcoming Visits
        if (currentAdminMode != null && currentAdminMode !== props.administrationMode) {
            setUpPage();
            setLoadingData(true);
        }
    }, [props.administrationMode]);

    useEffect(() => {
        //This will trigger if the data changes, but will only process if the loadingData is set to true.
        if (loadingData && canAccessPage && 
            ((startDate && endDate) || (!startDate && !endDate)) &&
            locationSetUp && dateSetUp && selectedLocations != null) {

            let filtersArray = getFilters();

            if (
                currentTab === 0 &&
                currentPageNumber.current === pageNumber &&
                currentPageSize.current === pageSize &&
                currentSearchTerm.current === searchTerm &&
                currentTableSortBy.current === tableSortBy &&
                currentTableSortDir.current === tableSortDir &&
                currentFilters.current === filtersArray.length &&
                currentStartDate.current === startDate &&
                currentEndDate.current === endDate && 
                currentShowUnallocatedJobs.current === showUnallocatedJobs
            ) {
                setLoadingData(false);
                //If none of the values have changed, then 2 have been updated at once, so prevent the listing from performing another call.
                return;
            }

            currentPageNumber.current = pageNumber;
            currentPageSize.current = pageSize;
            currentSearchTerm.current = searchTerm;
            currentTableSortBy.current = tableSortBy;
            currentTableSortDir.current = tableSortDir;
            currentFilters.current = filtersArray.length;
            currentStartDate.current = startDate;
            currentEndDate.current = endDate;
            currentShowUnallocatedJobs.current = showUnallocatedJobs;

            if (currentTab === 0) {
                const jobsRequest = {
                    requestCount: pageSize,
                    pageNumber: pageNumber,
                    sortField: tableSortBy.sortName,
                    sortDirection: tableSortDir,
                    filters: filtersArray
                };

                const loadDataPromises = [];

                var getJobsResponse = null;
                var assigneeResponse = null;

                const getJobs = JobService.getJobs(jobsRequest)
                    .then(response => getJobsResponse = response);
                loadDataPromises.push(getJobs);

                if (props.administrationMode) {
                    const getJobAssigneeNames = JobService.getJobAssigneeNames(filtersArray)
                        .then(response => assigneeResponse = response);
                    loadDataPromises.push(getJobAssigneeNames);
                }

                axios.all(loadDataPromises).then(function () {
                    let data = [];

                    if (getJobsResponse?.data != null) {
                        data = mapJobData(data, getJobsResponse);
                    }

                    if (assigneeResponse != null) {
                        setUpFilters(assigneeResponse);
                    }

                    setTableRows(data);
                    setTotalRecords(getJobsResponse.totalCount);
                    setLoadingData(false);
                    focusOnTextBox(searchInputRef);
                }, function () {
                    NotificationToaster.show(Intent.DANGER, "Visits could not be loaded. Please try loading the page again.");
                    setLoadingData(false);
                });
            } else {
                const jobsByContractsRequest = {
                    filters: getFilters()
                };
                JobService.getJobsByContracts(jobsByContractsRequest).then(r => {
                    let data = [];

                    if (r != null) {
                        data = r.map((d) => {
                            d.expanded = true;
                            d.contractLines.map((cl) => {
                                cl.expanded = true;
                                cl.jobs =
                                    cl.jobs.map((j) => {
                                        return [
                                            j.jobId,
                                            <StringToEllipsesString length={productDescLength} key={`prodname-${j.jobId}`} showTooltip={true}>{j.productName}</StringToEllipsesString>,
                                            j.locationName,
                                            <DateToLocal format='DD/MM/YYYY' key={`job-date-${j.jobId}`}>{j.scheduledDate}</DateToLocal>,
                                            cl.unitPrice,
                                            <FormCheckbox checked={false} key={`checkbox-${j.jobId}`} id={`checkbox-${j.jobId}`} />
                                        ];
                                    })
                                return cl;
                            });
                            return d;

                        });
                        setContracts(data);
                        setLoadingData(false);
                    }


                }, function () {
                    NotificationToaster.show(Intent.DANGER, "Jobs By Contract could not be loaded. Please try loading the page again.");
                    setLoadingData(false);
                });
            }
        }

    }, [loadingData, pageSize, pageNumber, tableSortBy, tableSortDir, appliedFilters, selectedLocations, currentTab, showUnallocatedJobs]);

    useEffect(() => {
        if (debouncedSearchTerm !== null && debouncedSearchTerm !== currentSearchTerm.current) {
            setPageNumber(1);
            setLoadingData(true);
        }
    }, [debouncedSearchTerm]);

    useEffect(() => {
        if (!loadingData) {
            dispatch(SetFiltering(jobLocationReduxName,
                {
                    list: selectedLocations
                }
            ));
        }
    }, [selectedLocations]);

    useEffect(() => {
        //We know the redux store has loaded now
        setDates();
    }, [reduxDates]);

    useEffect(() => {
        //We only want to set the locations once, else it gets stuck in a loop
        if (!locationSetUp) {
            setupLocationInfo();
        }
    }, [reduxLocations]);

    useEffect(() => {
        //We know the redux store has loaded now
        if (reduxSearch != null && reduxSearch.length > 0 && reduxSearch !== searchTerm) {
            setSearchTerm(reduxSearch);
        }
    }, [reduxSearch]);

    function mapJobData(data, r) {
        data = r.data.map((d) => {
            let statusBlock = setupStatusBlock(d);
            let duration = '';

            if (d.flsCallDuration != null) {
                let hours = Math.floor(d.flsCallDuration / 60);
                let mins = d.flsCallDuration - (hours * 60);
                duration = hours + " hours " + mins + " mins";
            }
            let scheduledDate = null;
            const isRemote = d.productName.toLowerCase().indexOf('remote') !== -1;
            if (isRemote) {
                scheduledDate = <DateToLocal key={`sch-date-${d.jobId}`}>{d.scheduledDate}</DateToLocal>;
            }
            else {

                var dateUtcMoment = moment.utc(d.scheduledDate);

                if (dateUtcMoment.isValid()) {
                    var dateUtc = dateUtcMoment.toDate();
                    var fromDate = moment(dateUtc).add(-1 * thirtyMinutes, 'minute');
                    var toDate = moment(dateUtc).add(thirtyMinutes, 'minute');
                    scheduledDate = moment(fromDate).local().format("DD/MM/YYYY HH:mm") + ' - ' + moment(toDate).local().format("HH:mm");
                } else {
                    scheduledDate = '-';
                }
            }
            let actionAcknowledge = null;
            //Can only acknowledge jobs with flsScheduledStatus (Fixed or Confirmed at time of writing this comment).
            if (d.flsScheduledStatus && !d.acknowledged && (d.amendmentType == null || d.amendmentType.length < 1)) {
                actionAcknowledge = <Tooltip content='Click to acknowledge visit.'><Button icon='plus' minimal={true} large={false} onClick={() => onAcknowledgeRowClick(d.jobId)}></Button></Tooltip>;
            }

            if (props.administrationMode) {
                return [(<Link to={`/job/${d.jobId}`} key={`job-link-${d.jobId}`}>{d.jobId}</Link>), (<StringToEllipsesString key={`prod-desc-${d.jobId}`} length={productDescLength} showTooltip={true}>{d.productName}</StringToEllipsesString>),
                    statusBlock, d.pubsJobId, d.assetRegistration, d.assetType, d.locationName, d.postcode,
                <DateToLocal format='DD/MM/YYYY' key={`due-date-${d.jobId}`}>{d.dueDate}</DateToLocal>,
                    <DateToLocal hideTimeIfMidnight={ true} key={`comp-date-${d.jobId}`}>{d.completedScheduledDate}</DateToLocal>, duration,
                d.state, d.assigneeName, d.flsStatus, <div className="button-row-small" key={`action-${d.jobId}`}>{actionAcknowledge}</div>
                ];
            }

            let actionAmend = null;
            if (d.amendmentType == null || d.amendmentType.length < 1) {
                actionAmend = <Tooltip content='Click to request amendment to visit.'><Button icon='edit' minimal={true} large={false} onClick={() => onAmendRowClick(d.jobId, d.productName, d.locationName)}></Button></Tooltip>;
            }

            return [d.pubsJobId ?? d.jobId, scheduledDate, d.productName, statusBlock,
            d.assetRegistration, d.assetType, d.locationName, duration, d.state,
            <div className="button-row-small inline-items" key={`actions-${d.jobId}`}>
                {actionAmend}{actionAcknowledge}
            </div>
            ];
        });
        return data;
    }

    function resetCurrent() {
        currentPageNumber.current = null;
        currentPageSize.current = null;
        currentSearchTerm.current = null;
        currentTableSortBy.current = null;
        currentTableSortDir.current = null;
        currentFilters.current = null;
        currentStartDate.current = null;
        currentEndDate.current = null;
    }

    function setUpPage() {
        setCurrentAdminMode(props.administrationMode);

        if (!props.administrationMode) {
            setShowFilters(false);
        }

        //Just to ensure page loads when switching between Job admin and upcoming visits
        resetCurrent();

        //This is either Internal jobs admin or upcoming Visits
        if (props.administrationMode) {
            dispatch({ type: 'SITE_FULL_WIDTH' });

            setTableHeaders(tableHeadersJobAdmin);

        } else {
            setTableHeaders(tableHeadersUpcomingJobs);
        }
    }

    function setUpFilters(assignees) {

        //Filer on state will only be internal
        var itemStates = [
            { "id": newState, "name": newState },
            { "id": waitingState, "name": waitingState },
            { "id": pendingState, "name": pendingState },
            { "id": readyState, "name": readyState },
            { "id": inProgressState, "name": inProgressState },
            { "id": doneState, "name": doneState },
            { "id": cancelledState, "name": cancelledState },
            { "id": deemedDoneState, "name": deemedDoneState },
            { "id": overrideDeemedDoneState, "name": overrideDeemedDoneState },
            { "id": doneIncompleteState, "name": doneIncompleteState },
            { "id": failedAppointmentState, "name": failedAppointmentState },
        ];

        //Filter on business area will only be internal
        var itemBAs = [
            { "id": visBA, "name": visBA },
            { "id": tachoBA, "name": tachoBA },
            { "id": auditBA, "name": auditBA }
        ];

        var selectedAssignees = appliedFilters.filter(a => a.name === "AssigneeName" && a.value !== "Unassigned").map(function (f) {
            return f.value;
        });

        var itemAssignees = assignees.map(a => { return { "id": a, "name": a } });

        var reorderNeeded = false;
        selectedAssignees.forEach((item) => {
            if (!itemAssignees.some(obj => obj["id"] === item)) {
                itemAssignees.push({ "id": item, "name": item });
                reorderNeeded = true;
            }
        });

        if (reorderNeeded) {
            itemAssignees = itemAssignees.sort((a, b) => a.name.localeCompare(b.name));
        }

        itemAssignees.unshift({ "id": "Unassigned", "name": "Unassigned" });

        var filterArray = [
            {
                "displayName": "Status", "name": "State", "items": [
                    ...itemStates
                ]
            },
            {
                "displayName": "Business Areas", "name": "BusinessArea", "items": [
                    ...itemBAs
                ]
            },
            {
                "displayName": "Assignee Names", "name": "AssigneeName", "items": [
                    ...itemAssignees
                ]
            }
        ];
        
        setPageFilters(filterArray);
        
    }

    function onTabClick(index) {

        var clonedTabs = [
            ...tabs
        ];

        clonedTabs = clonedTabs.map(function (t, i) {
            return {
                ...t,
                selected: index === i
            }
        });

        setTabs(clonedTabs);
        setCurrentTab(index);
        setLoadingData(true);
    }

    function onShowUnallocatedJobsChange(event) {
        setshowUnallocatedJobs(!showUnallocatedJobs);
        setLoadingData(true);
    }

    function getFilters(){
        let mappedFilters = appliedFilters.map(function (f) {
            return {
                key: f.name,
                ...f
            }
        });

        let filtersArray = [...mappedFilters,
            { "key": "Search", "value": searchTerm },
            { "key": "ShowUnallocatedJobs", "value": showUnallocatedJobs }
        ];

        if (startDate && endDate) {
            filtersArray = [...filtersArray,
            { "key": "CompletedScheduledStartDate", "value": startDate },
            { "key": "CompletedScheduledEndDate", "value": endDate }
            ];
        }

        //External should only be able to see open jobs in this page
        if (!props.administrationMode) {

            let filterStateKey = "State";
            let filterBAKey = "BusinessArea";

            if (!showUnallocatedJobs) {
                filtersArray = [...filtersArray,
                    { "key": filterStateKey, "value": newState },
                    { "key": filterStateKey, "value": pendingState },
                    { "key": filterStateKey, "value": readyState },
                    { "key": filterStateKey, "value": inProgressState },
                    { "key": filterStateKey, "value": waitingState },
                    { "key": filterBAKey, "value": visBA },
                    { "key": filterBAKey, "value": tachoBA },
                    { "key": filterBAKey, "value": auditBA },
                    { "key": "FlsScheduled", "value": "true" }
                ];
            } 
        }

        if (selectedLocations != null) {
            selectedLocations.forEach(function (l) {
                filtersArray = [...filtersArray,
                { "key": "Location", "value": l }
                ];
            });
        }

        return filtersArray;
    }

    function setupStatusBlock(jobRow) {
        let statusBlockTooltip = null;
        let scheduledStatus = null;
        let scheduledHollow = false;
        let schedueledText = "Scheduled"
        let statusBlock = null;
        if (jobRow.flsScheduledStatus) {
            if (jobRow.amendmentType != null && jobRow.amendmentType.length > 0) {
                if (jobRow.amendmentType === 'Amendment') {
                    statusBlockTooltip = <span>Pending {jobRow.amendmentType.toLowerCase()} to <DateToLocal format='DD/MM/YYYY'>{jobRow.rescheduleDate}</DateToLocal> requested by {jobRow.amendmentBy} on <DateToLocal format='DD/MM/YYYY'>{jobRow.amendmentDate}</DateToLocal>.</span>
                } else {
                    statusBlockTooltip = <span>Pending {jobRow.amendmentType.toLowerCase()} requested by {jobRow.amendmentBy} on <DateToLocal format='DD/MM/YYYY'>{jobRow.amendmentDate}</DateToLocal>.</span>
                }
            } else if (jobRow.acknowledged) {
                statusBlockTooltip = <span>Acknowledged by {jobRow.acknowledgedBy} on <DateToLocal format='DD/MM/YYYY'>{jobRow.acknowledgedDate}</DateToLocal>.</span>
            } else {
                statusBlockTooltip = <span>Awaiting acknowledgement.</span>
            }

            if (jobRow.amendmentType != null) {
                scheduledStatus = jobRow.amendmentType === 'Amendment' ? 'Amber' : 'Red';
                schedueledText = jobRow.amendmentType === 'Amendment' ? 'Amendment' : 'Cancellation';
            } else {
                scheduledHollow = !jobRow.acknowledged;
            }
            statusBlock = <Tooltip content={statusBlockTooltip}>
                <StatusBlock status={scheduledStatus} hollow={scheduledHollow} bold={false} large={false}>{schedueledText}</StatusBlock>
            </Tooltip>;
        }
        return statusBlock;
    }

    function setDates() {
        if (reduxDates && reduxDates.startDate !== null && reduxDates.endDate !== null) {
            setStartDate(reduxDates.startDate?.format("YYYY-MM-DD"));
            setEndDate(reduxDates.endDate?.format("YYYY-MM-DD"));
        } else if (dateSetUp) {
            setStartDate(null);
            setEndDate(null);
        }
        else {
            let defaultdays = 60;
            if (props.administrationMode) {
                setStartDate(moment().add(defaultdays * -1, 'd').format("YYYY-MM-DD"));
                setEndDate(moment().add(defaultdays, 'd').format("YYYY-MM-DD"));
            } else {
                setStartDate(moment().format("YYYY-MM-DD"));
                setEndDate(moment().add(defaultdays, 'd').format("YYYY-MM-DD"));
            }
        }
        setDateSetUp(true);
        setLoadingData(true);
    }

    function setupLocationInfo() {
        LocationService.getFormLocationSelectorCustomLocations(['Tacho', 'Vis', 'Audit']).then(function (locations) {
            setLocationList(locations);
            setLocationSetUp(true);
            if (reduxLocations != null && reduxLocations.list.length > 0) {
                setSelectedLocations(reduxLocations.list);
            } else {
                setSelectedLocations(locations.location[0].items.map(l => l.id));
            }
        }, function (error) {
            NotificationToaster.show(Intent.DANGER, `Locations failed ${error}`);
        });
        setLoadingData(true);
    }

    function validateAmendmentDetails() {
        let amendRules = [{ fieldName: "reschedule", required: true }];
        let externalRules = [];

        if (amendmentDetails.reasonForChange !== null && amendmentDetails.reasonForChange.length < 10) {
            let requireScheduleData = {
                fieldName: "reasonForChange",
                valid: false,
                errorMessage: 'The ' + actionNoun + ' reason must be at least 10 characters long.'
            };
            externalRules.push(requireScheduleData);
        }

        if (amendmentDetails.reschedule) {
            amendRules.push({ fieldName: "scheduleDate", required: true });
            amendRules.push({ fieldName: "phoneNumber", required: true, type: "phone" });
        }

        validate(amendRules, amendmentDetails, externalRules);
    }

    function validateAcknowledgedOnBehalfOf() {
        let acknowledgedRules = [];
        if (isInternal) {
            acknowledgedRules = [{ fieldName: "acknowledgedOnBehalfOf", required: true }];
        }
        validate(acknowledgedRules, acknowledgedOnBehalfOf);
    }

    function onPagingChange(newPageNumber, newPageSize) {
        setPageNumber(newPageNumber);
        setPageSize(newPageSize);
        setLoadingData(true);
    }

    function onTableSort(header, direction) {
        setTableSortBy(header);
        setTableSortDir(direction);
        setLoadingData(true);
    }

    function onToggleFilter() {
        setShowFilters((prevState) => {
            return !prevState;
        })
    }

    function onFilterChange(listingFilters) {
        setLoadingData(true);
        setPageNumber(1);
        setAppliedFilters(listingFilters);

        if (listingFilters.length > appliedFilters.length) {
            setShowFilters(true);
        }
    }

    function onDateRangeChange(item) {
        if ((item?.startDate?.date != null && item?.endDate?.date != null) || (item?.startDate?.date == null && item?.endDate?.date == null)) {
            setPageNumber(1);

            //Using the redux store should mean the setDates is called
            dispatch(SetFiltering(dateFilterReduxName, {
                startDate: item.startDate?.date,
                endDate: item.endDate?.date
            }));
        }
    }

    function onLocationListOpened() {
        locationListOnFilterOpen.current = selectedLocations;
    }

    function onLocationListClose() {
        if (locationListOnFilterOpen.current.toString() !== selectedLocations.toString()) {
            setLoadingData(true);
        }
    }

    function onAmendRowClick(jobId, prodName, locationName) {
        setCurrentJobId(jobId);
        setShowAmend(true);
        validateAmendmentDetails();
        focusOnTextBox(amendmentInputRef);
        setProductName(prodName);
        setLocation(locationName);
    }

    function onAmendConfirm() {
        validateAmendmentDetails();
        if (isValid) {
            setSavingData(true);
            JobService.amendJob(currentJobId, amendmentDetails).then(function () {
                resetCurrent();
                closeAmend(true);
                NotificationToaster.show(Intent.SUCCESS, `Visit ${actionVerb === 'cancel' ? 'cancelled' : 'amended'} successfully`);
            },
                (error) => {
                    closeAmend(false);
                    NotificationToaster.show(Intent.DANGER, error);
                }
            );
        }
    }

    function closeAmend(reload) {
        validate([], {});
        resetActions();
        setAmendmentDetails((prevValue) => {
            return {
                ...prevValue,
                reschedule: true,
                scheduleDate: null,
                phoneNumber: "",
                reasonForChange: ""
            }
        });

        setSavingData(false);
        if (reload) {
            setLoadingData(true);
        }
        setShowAmend(false);
    }

    function onAmendCancel() {
        closeAmend(false);
    }

    function onAcknowledgeRowClick(jobId) {
        setCurrentJobId(jobId);
        validateAcknowledgedOnBehalfOf();
        setShowAcknowledge(true);
        focusOnTextBox(acknowledgedOnBehalfOfInputRef);
    }

    function focusOnTextBox(InputRef) {
        if (InputRef.current != null) {
            setTimeout(function () {
                InputRef.current.focus();
            });
        }
    }

    function closeAcknowledge(reload) {
        validate([], {});
        if (isInternal) {
            setAcknowledgedOnBehalfOf((prevValue) => {
                return {
                    ...prevValue,
                    acknowledgedOnBehalfOf: null
                }
            });
        }
        setSavingData(false);
        if (reload) {
            setLoadingData(true);
        }
        setShowAcknowledge(false);
    }

    function onAcknowledgeConfirm() {
        validateAcknowledgedOnBehalfOf();
        if (isValid) {
            setSavingData(true);
            JobService.acknowledgeJob(currentJobId, isInternal ? acknowledgedOnBehalfOf : { acknowledgedOnBehalfOf: null }).then(function () {
                resetCurrent();
                closeAcknowledge(true);
                NotificationToaster.show(Intent.SUCCESS, "Visit acknowledged successfully");
            },
                (error) => {
                    closeAcknowledge(false);
                    NotificationToaster.show(Intent.DANGER, error);
                }
            );
        }
    }

    function onAcknowledgeCancel() {
        closeAcknowledge(false);
    }

    function onAcknowledgedOnBehalfOfChange(item) {
        setAcknowledgedOnBehalfOf((prevValue) => {
            return {
                ...prevValue,
                acknowledgedOnBehalfOf: item.target.value
            }
        });
    }

    function onReasonForChangeChange(item) {
        setAmendmentDetails((prevValue) => {
            return {
                ...prevValue,
                reasonForChange: item.target.value
            }
        });
    }
    function resetActions() {
        setActionVerb("amend");
        setActionNoun("amendment");
    }


    function onRescheduleChange(item) {
        var value = item.target.value === "true";
        if (value) {
            resetActions();
        }
        else {
            setActionVerb("cancel");
            setActionNoun("cancellation");
        }
        setAmendmentDetails((prevValue) => {
            return {
                ...prevValue,
                reschedule: value,
                scheduleDate: null,
                phoneNumber: ""
            }
        });
    }

    function onScheduleDateChange(item) {
        setAmendmentDetails((prevValue) => {
            return {
                ...prevValue,
                scheduleDate: item? moment(item).format("YYYY-MM-DD") : null
            }
        });
    }

    function onPhoneNumberChange(item) {
        setAmendmentDetails((prevValue) => {
            return {
                ...prevValue,
                phoneNumber: item.target.value
            }
        });
    }

    function onSearchChange(item) {
        setSearchTerm(item.target.value);
        dispatch(SetFiltering(jobSearchReduxName, item.target.value));
    }

    function onResetJobCreationError(e, contractId, contractLineId) {
        e.stopPropagation();
        JobService.resetJobCreateError(contractLineId).then(function () {
            clearJobCreateError(contractId, contractLineId);
            NotificationToaster.show(Intent.SUCCESS, `Job creation will now re-process`);
        },
            (error) => {
                NotificationToaster.show(Intent.DANGER, error);
            }
        );
        
    }
    function clearJobCreateError(contractId, contractLineId) {
        const newArr = contracts.map(obj => {
            if (obj.contractId === contractId) {

                const newContractLines = obj.contractLines.map(obj1 => {
                    if (obj1.contractLineId === contractLineId) {
                        return { ...obj1, hasCreateJobError: false, createJobError: "" };
                    }
                    return obj1;
                });
                return { ...obj, contractLines: newContractLines };
            }

            return obj;
        });

        setContracts(newArr);
    }

    function onCollapsableClick(cIndex, clIndex) {
        var clonedContracts = [
            ...contracts
        ]

        var contract = clonedContracts[cIndex];

        if (contract) {
            if (clIndex !== undefined) {
                var contractLine = contract.contractLines[clIndex];
                if (contractLine) {
                    contractLine.expanded = !contractLine.expanded;
                }
            } else {
                contract.expanded = !contract.expanded;
            }
        }

        setContracts(clonedContracts);

    }

    function renderHeaderButton(contractId, contractLineId, isCreateJobError, errorMessage) {

        if (!isCreateJobError) {
            return <Fragment></Fragment>;
        }
        else {
            return (
                <Tooltip content={"Job creation errored" + (errorMessage !== "" ? " with error " + errorMessage : "") + " Click to re-process job creation for this contract line."} position={Position.TOP}>
                     <Button
                        onClick={(e) => {
                            onResetJobCreationError(e, contractId, contractLineId)
                        }}
                        text="Reset Create"
                        icon="reset"
                        iconOnly={true}
                        large={false}
                        intent="warning"
                    
                        minimal={true}>
                    </Button>               
                </Tooltip>
            );
        }

    }

    return (
        <UserAccess perform={props.requiredAction}
            yes={() => (
                <div className="row">

                    <div className="inline-items spacer-bottom">
                        <h1>{props.title}</h1>
                    </div>

                    <ShowHide
                        evaluator={props.administrationMode}
                        show={( 
                            <div className="spacer-bottom">
                                <Tabs tabs={tabs} onClick={onTabClick} loading={loadingData} />
                            </div>
                        )}
                    />
                    
                    <ShowHide
                        evaluator={tableRows.length === 0 && !loadingData && pageFilters.length === 0}
                        hide={(
                            <Fragment>
                                <div className={classNames("pull-left", { "spacer-bottom": !showFilters })}>
                                    <div className="inline-items">
                                        <FormTextInput
                                            maxLength={50}
                                            inputRef={searchInputRef}
                                            placeholder="Product or Reference"
                                            onChange={onSearchChange}
                                            value={searchTerm}
                                            large
                                            disabled={loadingData}
                                            icon="search"
                                            id="job-search-field" />

                                        <FormLocationSelector
                                            businessArea={""}
                                            loading={loadingData}
                                            selectedLocations={selectedLocations}
                                            setSelectedLocations={setSelectedLocations}
                                            useHierarchy={false}
                                            useLocationDefaults={false}
                                            locationDefaultsToTrue={true}
                                            onLocationListClose={onLocationListClose}
                                            onLocationListOpened={onLocationListOpened}
                                            customLocations={locationList}
                                            useCustomLocations={true}
                                        />
                                        <FormDateRangeInput
                                            onChange={onDateRangeChange}
                                            startDate={startDate}
                                            endDate={endDate}
                                            disabled={loadingData}
                                            headingText={""}
                                            large
                                        ></FormDateRangeInput>

                                        <ShowHide
                                            evaluator={!props.administrationMode}
                                            hide={(
                                                <Fragment>
                                                    <Tooltip content="Filter" position="right">
                                                        <Button icon="filter" onClick={onToggleFilter} className={classNames({ "active": appliedFilters.length > 0 })} />
                                                    </Tooltip>
                                                </Fragment>
                                            )}
                                        />

                                        <FormSwitch
                                            disabled={loadingData}
                                            loading={loadingData}
                                            label="Show Unallocated Jobs "
                                            helperText=""
                                            checked={showUnallocatedJobs}
                                            onChange={onShowUnallocatedJobsChange}>
                                        </FormSwitch>
                                    </div>
                                </div>
                                <Filter filterName="reviews" visible={showFilters} filters={pageFilters} onUpdate={onFilterChange} disabled={showUnallocatedJobs} />
                            </Fragment>
                        )}
                    />
                    <ShowHide
                        evaluator={currentTab === 0}
                        show={(
                            <Fragment>
                                <ListingTable
                                    id="listing-table-location"
                                    headers={tableHeaders}
                                    data={tableRows}
                                    totalRecordCount={totalRecords}
                                    onPagingChange={onPagingChange}
                                    loadingData={loadingData}
                                    noDataMessage={noDataMessage}
                                    sortable
                                    sortableHeaders={sortableHeaders}
                                    sortedBy={tableSortBy}
                                    sortedDir={tableSortDir}
                                    onSort={onTableSort}
                                    />

                    <AlertConfirm
                        title="Please confirm acknowledgement"
                        isOpen={showAcknowledge}
                        saving={loadingData || savingData}
                        onConfirm={onAcknowledgeConfirm}
                        onCancel={onAcknowledgeCancel}
                        disabled={!isValid}
                    >
                        <ShowHide
                            evaluator={!isInternal}
                            hide={(
                                <Fragment>
                                    <FormTextInput
                                        id="acknowledged-on-behalf-of"
                                        headingText="Acknowledged on behalf of:"
                                        placeholder="Employee name"
                                        value={acknowledgedOnBehalfOf.acknowledgedOnBehalfOf}
                                        large
                                        maxLength={255}
                                        onChange={onAcknowledgedOnBehalfOfChange}
                                        dangerHelperText={errors.acknowledgedOnBehalfOf}
                                        inputRef={acknowledgedOnBehalfOfInputRef}
                                    />
                                </Fragment>
                            )}
                        />
                        <p>Are you sure you want to acknowledge this visit, it will no longer appear on the daily email reminder?</p>
                    </AlertConfirm>

                    <AlertConfirm
                        title={"Please confirm " + actionNoun}
                        isOpen={showAmend}
                        saving={loadingData || savingData}
                        onConfirm={onAmendConfirm}
                        onCancel={onAmendCancel}
                        disabled={!isValid}
                    >
                        <div className="clearfix">
                            <div className="pull-left">
                                <NotificationInline
                                    id="amendment-warning"
                                    text="If you are within 2 working days of the appointment time full cost will be charged. 
                                            Please check your inspection type requirements and ensure that by cancelling this appointment, your compliance schedule is not compromised. 
                                            If you still wish to cancel this appointment please fill in the details below."
                                    intent="warning"
                                    allowClose={false}
                                    show={true}
                                >
                                </NotificationInline>
                                <div>
                                    <FormTextArea
                                        id="reason-for-amendment"
                                        headingText={"Reason for " + actionNoun + ":"}
                                        placeholder="Description"
                                        value={amendmentDetails.reasonForChange}
                                        large
                                        maxLength={1000}
                                        onChange={onReasonForChangeChange}
                                        dangerHelperText={errors.reasonForChange}
                                        inputRef={amendmentInputRef}
                                    />
                                    <FormRadio
                                        onChange={onRescheduleChange}
                                        headingText="Reschedule or cancel visit:"
                                        options={[{ value: true, label: "Reschedule" }, { value: false, label: "Cancel" }]}
                                        selectedValue={amendmentDetails.reschedule}
                                        id="reschedule-cancel-job"
                                    ></FormRadio>

                                                <ShowHide
                                                    evaluator={!amendmentDetails.reschedule}
                                                    hide={(
                                                        <Fragment>
                                                            <FormDateInput
                                                                headingText="Please select a prefered date. You will be contacted shortly to confirm availability or discuss alternative appointments:"
                                                                onChange={onScheduleDateChange}
                                                                value={amendmentDetails.scheduleDate}
                                                                minDate={moment().toDate()}
                                                                maxDate={moment().add(rescheduleMaxDateDaysInFuture, 'd').toDate()}
                                                                id="input-tacho-start-date"
                                                                dangerHelperText={errors.scheduleDate}
                                                                disabled={false}
                                                                loading={loadingData}
                                                            ></FormDateInput>
                                                            <FormTextInput
                                                                id="amend-phone-number"
                                                                headingText="Contact phone number:"
                                                                placeholder="Phone number"
                                                                value={amendmentDetails.phoneNumber}
                                                                large
                                                                maxLength={50}
                                                                onChange={onPhoneNumberChange}
                                                                dangerHelperText={errors.phoneNumber}
                                                                disabled={loadingData}
                                                            />
                                                        </Fragment>
                                                    )}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <p>Are you sure you want to {actionVerb} this {productName} visit for {location} visit location?</p>
                                    </AlertConfirm>
                                
                            </Fragment>
                        )}
                    />
                    
                    <ShowHide
                        evaluator={currentTab === 1}
                        show={(
                            <Fragment>
                                <div className="clear-divide row">
                                    {
                                        contracts.map((contract, cIndex) => {
                                            return (
                                                <Collapsable
                                                    key={"contract-" + contract.contractId}
                                                    loading={loadingData}
                                                    title={"Tacho Contract " + contract.contractName + " - " + contract.contractNumber}
                                                    fullWidth={true}
                                                    expanded={contract.expanded}  
                                                    lightMode={true}
                                                    onClick={() => onCollapsableClick(cIndex)}
                                                >      
                                                    <div className="collapsable-content-lightMode">                                                    
                                                    {                                                        
                                                        contract.contractLines.map((cl, clIndex) => {
                                                            return (
                                                                        <Collapsable
                                                                            key={"contractline-" + cl.contractLineId}
                                                                            loading={loadingData}
                                                                            title={(
                                                                                <div className="inline-items">
                                                                                    <div>{"Tacho Contract Line " + cl.contractLineId}</div>
                                                                                    <div className="inline-item">&nbsp;</div>
                                                                                </div>
                                                                            )}
                                                                            fullWidth={true}
                                                                            expanded={cl.expanded}
                                                                            lightMode={true}
                                                                            onClick={() => onCollapsableClick(cIndex, clIndex)}
                                                                    headerButtons={renderHeaderButton(contract.contractId, cl.contractLineId, cl.hasCreateJobError, cl.createJobError)}
                                                                        >
                                                                            <div className="collapsable-content-lightMode">
                                                                                <ListingTable
                                                                                    id={"listing-table-contractline-" + cl.contractLineId}
                                                                                    headers={["Job Id", "Product Name", "Location", "Date", "Unit Price", <FormCheckbox checked={false} key={`checkbox-selectall-${cl.contractLineId}`} id={`checkbox-selectall-${cl.contractLineId}`} label="SELECT ALL" />]}
                                                                                    data={cl.jobs}
                                                                                    totalRecordCount={cl.jobs.length}
                                                                                    loadingData={loadingData}
                                                                                    noDataMessage={noDataMessage}
                                                                                    pageable={false}
                                                                                />
                                                                            </div>
                                                                            <div className="spacer-bottom">

                                                                            </div>

                                                                        </Collapsable>
                                                            );
                                                        })                                                        
                                                    }
                                                    </div>
                                                </Collapsable>
                                            );
                                        })
                                    }
                                </div>
                                
                            </Fragment>
                        )}
                        />
                    

                    
                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );
}