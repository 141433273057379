import ApiService from 'services/ApiService'

const badRequestCode = 400;

export const OperatingLicenceService = {

	getOptions() {
		return new Promise((resolve, reject) => {
			ApiService.get('/asset/options/operatorlicence').then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	getLicences() {
		return new Promise((resolve, reject) => {
			ApiService.get('/asset/OperatingLicences').then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},

	setFavourite(id) {
		return new Promise((resolve, reject) => {
			ApiService.post(`/asset/OperatingLicences/${id}/favourite`).then(
				(result) => {
					resolve(result);
				},
				(error) => {
					if (error.response.status === badRequestCode) {
						reject(error.response);
					} else {
						reject("Unable to favourite licence. Please try again later.");
					}
				}
			)
		})
	},
	getLicence(licenceId) {
		return new Promise((resolve, reject) => {
			ApiService.get(`/asset/OperatingLicences/${licenceId}`).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error);
				});
		});
	},
	deleteLicence(licenceId) {
		return new Promise((resolve, reject) => {
			ApiService.delete(`/asset/OperatingLicences/${licenceId}`).then(
				(result) => {
					resolve();
				},
				(error) => {
					reject(error);
				});
		});
	},
	updateOperatingLicence(licenceId, licenceDetails) {
		return new Promise((resolve, reject) => {
			ApiService.put(`/asset/OperatingLicences/${licenceId}`, licenceDetails).then(
				(result) => {
					resolve(result.data);
				},
				(error) => {
					reject(error.response);
				});
		});
	}
}