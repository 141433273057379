import PropTypes from 'prop-types';
import React from 'react';

export function GridLayout({numberOfColumns, gap, children, className}) {

    let columnCss = "1fr ".repeat(numberOfColumns).trim();

    return (
        <div className={`col-grid ${className}`} style={{"gridTemplateColumns": columnCss, "gap": `${gap}px`}}>
            {children}
        </div>
    );

}

GridLayout.defaultProps = {
    gap: 20,
    className: ""
};

GridLayout.propTypes = {
    numberOfColumns: PropTypes.number.isRequired,
    gap: PropTypes.number,
    className: PropTypes.string
};