import { Intent } from '@blueprintjs/core';
import { ButtonSave, Button } from 'components/buttons';
import { FormSelect } from 'components/form-fields';
import { AlertConfirm, Modal, NotificationToaster, NotificationInline } from 'components/notifications';
import React, { useEffect, useState, Fragment } from 'react';
import { JobService } from 'services';
import { UserAccess } from 'components/user-access/UserAccess';
import { useValidation } from "hooks/useValidation";
import { PageRestricted } from 'pages/errors/page-restricted';
import { useDirtyData } from "hooks/useDirtyData";
import PropTypes from 'prop-types';

export function CancelJob(props) {

    const [isCancelJobValid, cancelJobErrors, cancelJobValidate] = useValidation();
    const [showCancelJobModal, setShowCancelJobModal] = useState(false);
    const [savingJobCancel, setSavingJobCancel] = useState(false);    
    const [selectedCancelReason, setSelectedCancelReason] = useState("");
    const [showJobCancelConfirm, setShowJobCancelConfirm] = useState(false);

    const requiredAction = ["Internal"];
    const isDataDirty = useDirtyData("", selectedCancelReason);
    const rules = [
        { fieldName: "cancelJobReason", required: true }
    ];

    const cancelReasons = [
        { id: '0', name: 'No longer required' },
        { id: '1', name: 'Depot closed' },
        { id: '3', name: 'Service cancelled' },
        { id: '4', name: 'Reduction in service' },
        { id: '5', name: 'Admin error' }
    ];

    useEffect(validateModel, [isDataDirty, selectedCancelReason]);

    useEffect(() => {
        cancelJobValidate(rules, selectedCancelReason);
    }, [selectedCancelReason]);

    useEffect(() => {
        setShowCancelJobModal(props.showCancelModal);
    }, [props.showCancelModal]);


    function validateModel() {
        if (isDataDirty) {
            cancelJobValidate(rules, selectedCancelReason);
        }
    }

    function closeCancelModal() {
        if (props.onJobCancelClose !== null) {
            props.closeCancelModal();
        };
        setShowCancelJobModal(false);
    }

    function cancelJob() {
        setShowJobCancelConfirm(true);
    }

    function onCancelJobConfirm() {
        if (!isCancelJobValid) {
            return;
        }

        setShowJobCancelConfirm(false);

        setSavingJobCancel(true);

        JobService.cancelJob(props.jobId, selectedCancelReason).then(function () {
            NotificationToaster.show(Intent.SUCCESS, `The Job has been cancelled successfully.`);
        }, function (error) {
            NotificationToaster.show(Intent.DANGER, error);
        }).finally(function () {
            setSavingJobCancel(false);
            closeCancelModal();
        });
    }

    function onJobCancelCancel() {
        setShowJobCancelConfirm(false);
    }

    function onChangeReason(item) {
        setSelectedCancelReason(item.id);
    }

    return (

        <UserAccess perform={requiredAction}
            yes={() => (
                <Fragment>
                    <Modal
                        isOpen={showCancelJobModal}
                        title="Are you sure you want to cancel this job?"
                        showCloseButton={false}
                        clickOutsideClose={false}
                    >
                        <div>
                            <NotificationInline
                                allowClose={false}
                                show
                                text="Please note, once you cancel this job, the action cannot be reversed."
                                intent={Intent.WARNING}
                            />                                                        
                        </div>
                        
                        <FormSelect
                            disabled={false}
                            items={cancelReasons}
                            placeholder="Select a reason"
                            headingText="Cancel Job Reason:"
                            onItemSelect={onChangeReason}
                            loading={props.loading}
                            dangerHelperText={cancelJobErrors.cancelJobReason}
                            selectedValue={selectedCancelReason}
                            id="input-cancel-reason"
                        ></FormSelect>

                        <AlertConfirm
                            title="Please confirm"
                            isOpen={showJobCancelConfirm}
                            onConfirm={onCancelJobConfirm}
                            onCancel={onJobCancelCancel}
                        >
                            <p>Are you sure you want to cancel this job?</p>
                        </AlertConfirm>
                        
                        <div className="bp3-dialog-footer">
                            <div className="bp3-dialog-footer-actions">
                                <ButtonSave text={"Cancel Job"} icon={null} intent={Intent.DANGER} onClick={cancelJob} simpleDisabled={!isCancelJobValid && isDataDirty} disabled={savingJobCancel}></ButtonSave>
                                <Button text="Close" onClick={() => { closeCancelModal(); }} disabled={savingJobCancel}></Button>
                            </div>
                        </div>
                    </Modal>
                </Fragment>

            )}
            no={() => (
                <PageRestricted />
            )}
        />

    );
}

CancelJob.defaultProps = {
    addressSettings: null,
    loading: false,
    closeCancelModal: null,
    showCancelModal: false
}

CancelJob.propTypes = {
    jobId: PropTypes.number,
    loading: PropTypes.bool.isRequired,
    closeCancelModal: PropTypes.func,    
    showCancelModal: PropTypes.bool.isRequired
};