import React, { useState, useEffect } from 'react';
import { PageRestricted } from 'pages/errors/page-restricted';
import { UserAccess, UserHasAccess } from 'components/user-access';
import { LinkService } from 'services';
import { AlertConfirm } from 'components/notifications';
import { Button } from "components/buttons"
import { Position } from '@blueprintjs/core';
import { ShowHide } from 'components/layout';
import PropTypes from 'prop-types';
import { HelperFunctions } from 'helpers';
import { AssociatedAssetsModal } from "pages/asset/associated-assets/AssociatedAssetsModal";
import { ListingTable } from 'components/listing';
import { AssetStatus } from 'components/status/AssetStatus';
import { Tooltip } from "components/tooltip";
import { InternalLink } from 'components/navigation';

export function AssociatedAssets(props) {
    const requiredAction = ["Fleet:View", "FleetPCN:View", "FleetVIS:View"];
    const requiredEditActions = ["Fleet:Edit", "FleetPCN:Edit", "FleetVIS:Edit"];
    const canAccessPage = UserHasAccess(requiredAction);
    const canEditPage = UserHasAccess(requiredEditActions);
    const viewHeaders = ['ID/Registration', 'Asset Type', 'Asset Status', 'Asset Ownership', 'Location', 'Reference'];
    const editHeaders = [...viewHeaders, 'Delete'];

    const [deleting, setDeleting] = useState(false);
    const [assetListFormatted, setAssetListFormatted] = useState([]);
    const [assetIdToDelete, setAssetIdToDelete] = useState(0);

    const [showAssociatedAssetsModal, setShowAssociatedAssetsModal] = useState(false);

    useEffect(() => {
        if (!canAccessPage || !props.assetProfile
            || !props.assetOptions || !props.assetOptions.assetTypes || props.assetOptions.assetTypes.length < 1
            || !props.assetTypeDisplay || props.assetTypeDisplay.length < 1
            || !props.assetStatusesMappings || props.assetStatusesMappings.length < 1
            || !props.ownershipAttributeMappings || props.ownershipAttributeMappings.length < 1
        ) {
            return;
        }

        setAssetListFormatted(props.assetProfile.relatedAssets.filter((a) => a.registration).map((a) => {
            let row = [
                (<div key={`associated-asset-list-${a.assetId}`} className="inline-items"><AssetStatus statusId={a.assetStatus?.id} ownershipId={a.assetOwnership?.id} isLoading={false} statusAttributeMappings={props.assetStatusesMappings} ownershipAttributeMappings={props.ownershipAttributeMappings} tooltipPosition={Position.RIGHT} />
                    <h4 className="no-margin">
                        <Tooltip position={Position.TOP} content={`Asset Profile`}>
                            <InternalLink link={LinkService.getVehicleProfileUrl(a.assetId, 'details')}>{a.registration}</InternalLink>
                        </Tooltip>
                    </h4></div>),
                a.assetType.name,
                a.assetStatus.name,
                a.assetOwnership.name,
                a.location.name,
                a.fleetNumber];
            if (canEditPage) {
                row.push(<div className="button-row-small inline-items" key={`delete-${a.assetId}`}>
                    <Button icon='trash' minimal={true} large={false} onClick={() => onDeleteRowClick(a.assetId)} disabled={props.loading || props.saving}></Button>
                </div>);
            }
            return row;
        }));

    }, [props.assetProfile, props.assetTypeDisplay, props.assetOptions, props.assetStatusesMappings, props.ownershipAttributeMappings, props.loading, props.saving]);


    function associatedAssetsModalShow() {
        setShowAssociatedAssetsModal(true);
    }

    function associatedAssetsModalClose() {
        setShowAssociatedAssetsModal(false);
    }


    function saveAssociatedAssets(associatedAssetId) {
        if (!canEditPage) {
            return;
        }
        //Ned to just get the changes into the profile
        let profile = HelperFunctions.deepClone(props.assetProfile);

        if (!props.assetProfile?.isAttachable) {
            profile.relatedAssets.push({ AssetId: associatedAssetId });
        } else {
            profile.linkedVehicleId = associatedAssetId;
        }

        props.saveProfile(profile, true);
    }

    useEffect(() => {
        if (!props.saving && showAssociatedAssetsModal) {
            setShowAssociatedAssetsModal(false);
        }
    }, [props.saving]);

    function onDeleteRowClick(idToDelete) {
        setAssetIdToDelete(idToDelete);
        setDeleting(true);
    }

    function onDeleteCancel() {
        setAssetIdToDelete(0);
        setDeleting(false);
    }

    function onDelete() {
        setDeleting(false);
        //Need to remove the asset from the profile
        let profile = HelperFunctions.deepClone(props.assetProfile);

        if (!props.assetProfile?.isAttachable) {
            profile.relatedAssets = profile.relatedAssets.filter(a => a.assetId !== assetIdToDelete);
        } else {
            profile.linkedVehicleId = null; 
        }

        props.saveProfile(profile, true);
    }

    return (
        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">
                    <h3>Associated Assets</h3>

                    <ShowHide
                        evaluator={canAccessPage}
                        show={(
                            <>
                                <ShowHide
                                    evaluator={canEditPage}
                                    show={(
                                        <Button className="spacer-bottom" text="Add Associated Asset" intent="primary" id="add-associated-asset-button" onClick={associatedAssetsModalShow}
                                            disabled={props.loading || props.saving || (props.assetProfile?.isAttachable && assetListFormatted.length > 0)} />
                                    )}
                                />

                                <ListingTable
                                    id="associated-asset-listing"
                                    headers={canEditPage ? editHeaders : viewHeaders}
                                    data={assetListFormatted}
                                    loadingData={props.loading}
                                    noDataHeading="No associated assets"
                                    totalRecordCount={props.assetProfile?.relatedAssets ? props.assetProfile.relatedAssets.length : 0}
                                    pageable={false}
                                    showPerPage={false}
                                />

                                <AlertConfirm
                                    title="Delete associated asset"
                                    isOpen={deleting}
                                    onConfirm={onDelete}
                                    onCancel={onDeleteCancel}
                                    confirmButtonIntent="primary"
                                    confirmButtonText="Delete"
                                    saving={props.saving}
                                >
                                    <p>{"Are you sure you want to delete this associated asset?"}</p>
                                </AlertConfirm>

                                <AssociatedAssetsModal
                                    showModal={showAssociatedAssetsModal}
                                    onCloseModal={associatedAssetsModalClose}
                                    onSave={saveAssociatedAssets}
                                    selectedAsset={props.assetProfile}
                                    assetOptions={props.assetOptions}
                                    assetStatusesMappings={props.assetStatusesMappings}
                                    ownershipAttributeMappings={props.ownershipAttributeMappings}
                                    loading={props.loading}
                                    saving={props.saving}
                                />
                            </>
                        )}
                    />
                </div>
            )}
            no={() => (
                <PageRestricted />
            )}
        />
    );
}

AssociatedAssets.defaultProps = {
    assetProfile: {},
    saveProfile: () => { },
    loading: true,
    saving: false,
    assetTypeDisplay: {},
    assetOptions: {}
};

AssociatedAssets.propTypes = {
    assetProfile: PropTypes.object.isRequired,
    saveProfile: PropTypes.func.isRequired,
    assetTypeDisplay: PropTypes.array.isRequired,
    assetOptions: PropTypes.object.isRequired,
    assetStatusesMappings: PropTypes.array.isRequired,
    ownershipAttributeMappings: PropTypes.array.isRequired,
    loading: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired
};