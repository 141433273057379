import React, { useState, useEffect } from 'react';
import './Layout.css';
import classNames from 'classnames';

import { UserAccess, UserHasAccessActions } from 'components/user-access';
import { InternalMenuItem } from 'components/navigation';

import { useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';

import menuJson from './LeftMenu.json';
import Cookies from 'js-cookie';

export function LeftMenu() {

    const [menuItems, setMenuItems] = useState([]);
    let location = useLocation();
    const userActions = useSelector(state => state.loggedInUser.actions);
    const [topLocation, setTopLocation] = useState('');
    const [fullLocation, setFullLocation] = useState('');

    useEffect(function () {
        if (location != null && location.pathname) {
            setFullLocation(location.pathname);
            var urlSections = location.pathname.split('/');
            setTopLocation('/' + urlSections[1]);
        }
    }, [location]);

    useEffect(function () {
        parseSitePrefix(menuJson);
    }, []);

    useEffect(parseMenu, [userActions, menuJson]);
    useEffect(parseActiveStates, [topLocation]);

    function checkForCookie(cookieName) {
        return Cookies.get(cookieName) !== undefined;
    }

    function parseMenu() {
        if (userActions) {
            setMenuItems(menuJson);
        }
    }

    function getPermittedChildren(children) {
        return children.filter(child => {
            return UserHasAccessActions(child.role, userActions)
        });
    }

    function parseActiveStates() {
        if (menuItems.length === 0) {
            return;
        }

        var items = [
            ...menuItems
        ];

        for (var i = 0; i < items.length; i++) {
            var item = items[i];
            item.active = (item.url === topLocation || item.url === fullLocation);

            if (item.children != null) {
                var children = getPermittedChildren(item.children);

                if (!item.active) {
                    //Check to see if any of the children are active
                    item.active = areChildrenActive(children)
                }

                if (children.length === 1) {
                    //Need to make this the top level
                    item.name = children[0].name ? children[0].name : item.name;
                    item.icon = children[0].icon ? children[0].icon : item.icon;
                    item.url = children[0].url ? children[0].url : item.url;
                    item.role = children[0].role ? children[0].role : item.role;
                    item.sitePrefix = children[0].sitePrefix ? children[0].sitePrefix : item.sitePrefix;
                    item.id = children[0].id ? children[0].id : item.id;
                    item.children = null;
                }
            }
        }
        setMenuItems(items);
    }

    function parseSitePrefix(items) {
        for (var i = 0; i < items.length; i++) {
            var item = items[i];

            if (item.sitePrefix != null) {
                var prefix = "";

                if (item.newUrl && item.cookieName && checkForCookie(item.cookieName)) {
                    item.url = item.newUrl;
                    item.sitePrefix = null;
                }

                if (item.sitePrefix === "vision" && (!item.cookieName || (item.cookieName && !checkForCookie(item.cookieName)))) {
                    prefix = window.env.VISION_URL;
                } else if (item.sitePrefix === "memberadmin") {
                    prefix = window.env.VISIONMEMBER_URL;
                } else if (item.sitePrefix === "pcn") {
                    prefix = window.env.PCN_URL;
                }

                item.url = prefix + item.url;

                item.forceRedirect = prefix !== "";
            }

            if (item.children != null) {
                parseSitePrefix(item.children);
            }
        }
    }

    function areChildrenActive(items) {
 
        for (var i = 0; i < items.length; i++) {
            var item = items[i];

            if (item.url === topLocation || item.url === fullLocation) {
                return true;
            }
        }

        return false;
    }

    return (
        <div id="left-bar">
            <ul>
                {menuItems.map(function (menuItem) {
                    return (
                        <UserAccess key={menuItem.name} perform={menuItem.role}
                            yes={() => {
                                if (menuItem.children != null) {
                                    return (
                                        <li className={classNames({ "active": menuItem.active, "external": menuItem.external })}><span className="menu-icon"><i className={menuItem.icon} style={menuItem.iconStyle} id={menuItem.id} /></span>
                                            <div className="menu-popout">
                                                <h4>{menuItem.name}</h4>
                                                {!menuItem.children ? null : (
                                                    <ul>
                                                        {menuItem.children.map(function (childItem) {
                                                            return (
                                                                <UserAccess key={childItem.name} perform={childItem.role}
                                                                    yes={() => (
                                                                        <InternalMenuItem forceRedirect={childItem.sitePrefix == null ? false : true} text={childItem.name} location={childItem.url} id={childItem.id} />
                                                                    )}
                                                                />
                                                            );
                                                        })}
                                                    </ul>
                                                )}

                                            </div>
                                        </li>
                                    )
                                } else {
                                    return (
                                        <li className={classNames({ "active": menuItem.active, "external": menuItem.external })}>
                                            <InternalMenuItem nodeType="a" forceRedirect={menuItem.sitePrefix != null} text={(<i className={menuItem.icon} style={menuItem.iconStyle} />)} location={menuItem.url} className="menu-icon" id={menuItem.id} />
                                            <div className="menu-popout">
                                                <h4>{menuItem.name}</h4>
                                            </div>
                                        </li>
                                    );
                                }
                            }}
                        />
                    );
                })}
            </ul>
        </div>
    );
}

