import React, { useEffect, useRef, useState } from 'react';
import { Modal, NotificationToaster } from 'components/notifications';
import { AssetService } from 'services';
import { AssetStatus } from 'components/status/AssetStatus';
import PropTypes from "prop-types";
import { FormTextInput, FormCheckbox, FormLocationSelector } from 'components/form-fields';
import { ListingTable } from 'components/listing';
import { Filter } from 'components/filtering';
import { Button, ButtonSave } from 'components/buttons';
import classNames from 'classnames';
import { Intent, Tooltip, Position } from '@blueprintjs/core';
import { PageRestricted } from "pages/errors/page-restricted";
import { UserAccess } from "components/user-access";
import 'components/form-fields/FormFields.css';
import { HelperFunctions } from 'helpers';
import './AssociatedAssets.css';

export function AssociatedAssetsModal(props) {
    const listingPerPageDefault = 25;
    const requiredActions = ["Fleet:View", "FleetPCN:View", "FleetVIS:View"];
    const badRequestCode = 400;
    const sortedBy = "AssetRegistration";
    const sortedDir = "asc";
    const pageNumber = 1;

    const [searchTerm, setSearchTerm] = useState('');
    const [modalTitle, setModalTitle] = useState(<></>);
    const [assetList, setAssetList] = useState([]);
    const [assetListFormatted, setAssetListFormatted] = useState([]);
    const [assetListIsLoading, setAssetListIsLoading] = useState(true);
    const [assetListShowFilters, setAssetListShowFilters] = useState(false);
    const [assetListFilters, setAssetListFilters] = useState([]);

    const [assetListSelectedFilters, setAssetListSelectedFilters] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [assetListLocationsUseHierarchy, setAssetListLocationsUseHierarchy] = useState(true);
    const [assetListSelectedAsset, setAssetListSelectedAsset] = useState(null);

    const locationListOnFilterOpen = useRef(selectedLocations);
    const currentPayload = useRef(null);

    useEffect(() => {
        if (!props.assetOptions || !props.assetOptions.assetTypes || props.assetOptions?.assetTypes?.length < 1) {
            return;
        }

        setAssetListFilters([{
            "name": "Status",
            "displayName": "Status",
            "items": props.assetOptions.assetStatuses
        },
        {
            "name": "Ownership",
            "displayName": "Ownership",
            "items": props.assetOptions.assetOwnerships
        },
        {
            "name": "AssetType",
            "displayName": "Type",
            "items": props.assetOptions.assetTypes
        }]);

    }, [props.assetOptions]);

    useEffect(() => {
        if (!assetList) {
            return;
        }

        let existingIds = [];

        if (props.selectedAsset?.relatedAssets && props.selectedAsset.relatedAssets.length) {
            existingIds = props.selectedAsset.relatedAssets.map((a) => a.assetId);
        }

        var assetListRemoveExisting = assetList.filter((a) => { return !existingIds.includes(a.assetId) });

        setAssetListFormatted(assetListRemoveExisting.map((a) => {
            return [
                (<div key={`associated-asset-search-list-${a.assetId}`} className="inline-items"><AssetStatus statusId={a.assetStatus?.id} ownershipId={a.assetOwnership?.id} isLoading={false} statusAttributeMappings={props.assetStatusesMappings} ownershipAttributeMappings={props.ownershipAttributeMappings} tooltipPosition={Position.RIGHT} />
                    <h4 className="no-margin">{a.assetRegistration}</h4></div>),
                a.assetType.name,
                a.assetStatus.name,
                a.assetOwnership.name,
                a.location.name,
                a.fleetNumber,
                <div className="associated-asset-table-checkbox" key={`checkbox-${a.id}`}>
                    <FormCheckbox
                        checked={a.userSelected}
                        onChange={(event) => handleSelectedAsset(event, a.assetId)}
                    />
                </div>]
        }));
    }, [assetList]);

    useEffect(() => {
        if (!props.selectedAsset || !props.assetStatusesMappings || !props.ownershipAttributeMappings || props.assetStatusesMappings < 1 || props.ownershipAttributeMappings < 1) {
            return;
        }

        setModalTitle(
            <div className="inline-items space-large">
                <AssetStatus statusId={props.selectedAsset?.assetLocation?.assetStatus?.id} ownershipId={props.selectedAsset?.assetLocation?.assetOwnership?.id} isLoading={false} statusAttributeMappings={props.assetStatusesMappings} ownershipAttributeMappings={props.ownershipAttributeMappings} tooltipPosition={Position.RIGHT} />
                <h1 className="no-margin">{props.selectedAsset?.registration}{props.selectedAsset?.assetLocation?.fleetNumber ? ` / ${props.selectedAsset?.assetLocation?.fleetNumber}` : '' }</h1>
            </div>
        );
    }, [props.selectedAsset, props.assetStatusesMappings, props.ownershipAttributeMappings]);

    useEffect(() => {
        if (props.showModal) {
            handleAssetListSearch();
        } else {
            currentPayload.current = null;
            resetAssetSearchResults();
        }
    }, [assetListSelectedFilters, selectedLocations, searchTerm, sortedBy, sortedDir, props.showModal]);

    function onToggleAssetListFilter() {
        setAssetListShowFilters((prevState) => { return !prevState });
    }

    function onFiltersChange(filters) {
        setAssetListSelectedFilters(filters);

        if (filters.length > 0) {
            setAssetListShowFilters(true);
        }
    }

    function handleSelectedAsset(event, assetId) {
        let checked = event.target.checked;
        if (checked) {
            setAssetListSelectedAsset(assetId);
        } else {
            setAssetListSelectedAsset(null);
        }
        //Untick everything else
        setAssetList((prev) => {
            var newInstance = [
                ...prev
            ];
            return newInstance.map(a => { a.userSelected = checked ? a.assetId === assetId : false; return a; });
        });
    }

    function onLocationListOpened() {
        locationListOnFilterOpen.current = selectedLocations;
    }

    function resetAssetSearchResults() {
        setAssetList([]);
        setAssetListFormatted([]);
        setAssetListIsLoading(false);
        setAssetListSelectedAsset(null);
    }

    function getSearchPayload() {
        let filters = assetListSelectedFilters.map(x => {
            return { key: x.name, value: x.displayName }
        });

        if (selectedLocations != null) {
            selectedLocations.forEach(function (l) {
                filters = [...filters,
                { key: "Location", value: l }
                ];
            });
        }

        let attachbleFilter = props.selectedAsset?.isAttachable ? "NO" : "YES";
        filters.push({ key: 'ISATTACHABLE', value: attachbleFilter });

        return {
            requestCount: listingPerPageDefault,
            pageNumber: pageNumber,
            searchTerm: searchTerm,
            sortField: sortedBy,
            sortDirection: sortedDir,
            filters: filters
        };
    }

    function handleAssetListSearch() {
        //if no location filters are set, do not call the endpoint, just return empty:
        if (!selectedLocations || selectedLocations.length === 0) {
            resetAssetSearchResults();
            return;
        }

        let payload = getSearchPayload();

        if (HelperFunctions.deepEqual(payload, currentPayload.current)){
            return;
        }
        setAssetListIsLoading(true);
        currentPayload.current = payload;

        AssetService.searchAssets(payload).then((response) => {

            if (response.totalCount === 0) {
                resetAssetSearchResults();
                return;
            }
            setAssetList(response.data);
            setAssetListSelectedAsset(null);
        }).catch((error) => {
            resetAssetSearchResults();
            if (error.status === badRequestCode) {
                NotificationToaster.show(Intent.WARNING, error.data);
            } else {
                NotificationToaster.show(Intent.DANGER, "Asset search failed. Please try again.");
            }
        }).finally(function () {
            setAssetListIsLoading(false);
        });
    };

    function onSelectedLocationsChange(locations) {
        setSelectedLocations(locations);
    }

    function onSearchTextChange(searchText) {
        setSearchTerm(searchText);
    }

    function onCloseModal() {
        props.onCloseModal();
    }

    function handleSave() {
        if (!assetListSelectedAsset) {
            return;
        }
        props.onSave(assetListSelectedAsset);
    }

    return (
        <Modal
            isOpen={props.showModal}
            onClose={onCloseModal}
            clickOutsideClose={true}
            title={modalTitle}
            isCloseButtonShown={false}
            updateStateOnClose={false}
            wide={true}
            className="fixed-height"
        >
            <UserAccess perform={requiredActions}
                yes={() => {
                    return (
                        <>
                            <div className='inline-items'>
                                <FormLocationSelector
                                    businessArea={"Tacho"}
                                    loading={assetListIsLoading}
                                    onLocationListOpened={onLocationListOpened}
                                    selectedLocations={selectedLocations}
                                    setSelectedLocations={onSelectedLocationsChange}
                                    useLocationDefaults={true}
                                    useHierarchy={assetListLocationsUseHierarchy}
                                    locationDefaultsToTrue={true}
                                    setUseHierarchy={setAssetListLocationsUseHierarchy}
                                />
                                <FormTextInput placeholder="Search assets" onChange={(x) => onSearchTextChange(x.target.value)} value={searchTerm} large icon="search" id="user-search-field" />
                                <Tooltip content="Filter" position="right">
                                    <Button icon="filter" onClick={onToggleAssetListFilter} className={classNames({ "active": assetListFilters.length > 0, "hidden": assetListFilters.length === 0 })} />
                                </Tooltip>
                            </div>
                            <Filter filterName='assignAssociatedAssets' visible={assetListShowFilters} filters={assetListFilters} onUpdate={onFiltersChange} />
                            <div className='spacer'>
                                <h3>Assignable Assets</h3>
                            </div>
                            <div className={classNames("associated-asset-listing", { "associated-asset-listing-filter": assetListShowFilters, "associated-asset-listing-no-filter": !assetListShowFilters })} >
                                <ListingTable
                                    id="associated-asset-search-listing"
                                    headers={['ID/Registration', 'Asset Type', 'Asset Status', 'Asset Ownership', 'Location', 'Reference', 'Select']}
                                    data={assetListFormatted}
                                    loadingData={assetListIsLoading}
                                    totalRecordCount={assetListFormatted.length}
                                    sortedBy={sortedBy}
                                    sortedDir={sortedDir}
                                    noDataHeading="No assets found"
                                    allowTableScrollVertical={false}
                                    showPerPage={false}
                                    pageable={false}
                                    columnClasses={["associated-asset-table-col", "associated-asset-table-col", "associated-asset-table-col", "associated-asset-table-col", "associated-asset-table-col", "associated-asset-table-col", "associated-asset-table-col"]}
                                />
                            </div>


                            <div className='inline-items push-right spacer-top'>
                                <Button text="CANCEL" onClick={onCloseModal} disabled={props.saving} />
                                <ButtonSave
                                    text="SAVE AND CLOSE"
                                    intent={Intent.PRIMARY}
                                    onClick={handleSave}
                                    loading={false}
                                    simpleDisabled={!assetListSelectedAsset || props.loading}
                                    disabled={props.saving}
                                    icon="floppy-disk"
                                />
                            </div>
                        </>)
                }
                } no={() => <PageRestricted />}
            />
        </Modal>
    );
}

AssociatedAssetsModal.defaultProps = {
    assetStatusesMappings: null,
    ownershipAttributeMappings: null,
    loading: true,
    saving: false
}

AssociatedAssetsModal.propTypes = {
    showModal: PropTypes.bool.isRequired,
    onCloseModal: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    selectedAsset: PropTypes.object.isRequired,
    assetStatusesMappings: PropTypes.array.isRequired,
    ownershipAttributeMappings: PropTypes.array.isRequired,
    assetOptions: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired
}