import React, { Fragment, useEffect, useState } from "react";
import { FormSelect, FormSwitchList } from "components/form-fields";
import { UserAccess, UserHasAccess } from "components/user-access"
import { HelperFunctions } from "helpers";
import { ShowHide } from "components/layout";
import { AlertUnsavedData } from "components/notifications";
import { useDirtyData } from "hooks/useDirtyData";
import { ButtonSave } from "components/buttons";
import PropTypes from 'prop-types';

export function Preferences(props) {
    const { assetProfile, saveProfile, loading, saving, assetTypeDisplay, assetOptions } = props;
    const requiredAction = ["Fleet:View", "FleetPCN:View", "FleetVIS:View"];
    const requiredEditActions = ["Fleet:Edit", "FleetPCN:Edit", "FleetVIS:Edit"];

    const canAccessPage = UserHasAccess(requiredAction);
    const canEditProfile = UserHasAccess(requiredEditActions);
    const [preferences, setPreferences] = useState({});
    const isDirty = useDirtyData(assetProfile, preferences);

    useEffect(() => {
        if (!canAccessPage || !assetProfile
            || !assetOptions || assetOptions.length < 1
            || !assetTypeDisplay || assetTypeDisplay.length < 1
        ) {
            return;
        }

        setPreferences(HelperFunctions.deepClone(assetProfile));
    }, [assetProfile, assetTypeDisplay, assetOptions]);

    function onAnalysisRulesChange(value) {
        setPreferences((prev) => {
            var newInstance = {
                ...prev
            };
            if (!newInstance.analysisRules.id) {
                newInstance.analysisRules.id = {};
            }
            newInstance.analysisRules.id = value.id;
            return newInstance;
        });
    }

    function onVehicleOptionsChange(event) {
        var toggleId = parseInt(event.currentTarget.getAttribute('identifier'));
        if (toggleId === 1) {
            onDigitalVehicleChange(event.target.checked);
        }
        else if (toggleId === 2) {
            onVehicleMovedWithoutCardAlertChange(event.target.checked);
        }
    }

    function onDigitalVehicleChange(value) {
        setPreferences((prev) => {
            var newInstance = {
                ...prev
            };
            if (!newInstance.isDigital) {
                newInstance.isDigital = false;
            }
            newInstance.isDigital = value;
            return newInstance;
        });
    }

    function onVehicleMovedWithoutCardAlertChange(value) {
        setPreferences((prev) => {
            var newInstance = {
                ...prev
            };
            if (!newInstance.assetLocation.alertAssignJourneyInclude) {
                newInstance.assetLocation.alertAssignJourneyInclude = false;
            }
            newInstance.assetLocation.alertAssignJourneyInclude = value;
            return newInstance;
        });
    }

    function onDisplayOptionsChange(event) {
        var toggleId = parseInt(event.currentTarget.getAttribute('identifier'));
        if (toggleId === 1) {
            onDisplayTachoChange(event.target.checked);
        }
        else if (toggleId === 2) {
            onDisplayMaintenanceChange(event.target.checked);
        }
    }

    function onDisplayTachoChange(value) {
        setPreferences((prev) => {
            var newInstance = {
                ...prev
            };
            if (!newInstance.assetLocation.hideTacho) {
                newInstance.assetLocation.hideTacho = false;
            }
            newInstance.assetLocation.hideTacho = value;
            return newInstance;
        });
    }

    function onDisplayMaintenanceChange(value) {
        setPreferences((prev) => {
            var newInstance = {
                ...prev
            };
            if (!newInstance.assetLocation.hideVis) {
                newInstance.assetLocation.hideVis = false;
            }
            newInstance.assetLocation.hideVis = value;
            return newInstance;
        });
    }

    function savePreferences() {
        if (!canEditProfile) {
            return;
        }
        saveProfile(preferences);
    }

    return (
        <UserAccess perform={requiredAction}
            yes={() => (
                <div className="row">
                    <h3>Preferences</h3>
                    <FormSelect
                        id="tachograph-rules"
                        headingText="Tachograph Rules"
                        items={assetOptions.analysisRules}
                        onItemSelect={onAnalysisRulesChange}
                        selectedValue={preferences?.analysisRules?.id}
                        disabled={!canEditProfile || saving}
                        dynamicWidth={true}
                        loading={loading}
                    />

                    <FormSwitchList
                        items={[
                            {
                                id: 1,
                                name: "Digital Vehicle",
                                checked: preferences?.isDigital,
                            },
                            {
                                id: 2,
                                name: "Vehicle Moved Without Card Alert",
                                checked: preferences?.assetLocation?.alertAssignJourneyInclude,
                            }]}
                        onChange={onVehicleOptionsChange}
                        headingText="Vehicle Options"
                        loading={loading}
                        switchListName="vehicleOptionsList"
                        disabled={!canEditProfile || saving}
                        dangerHelperText=""
                        headingLevel="h3"
                    />

                    <FormSwitchList
                        items={[
                            {
                                id: 1,
                                name: "Display Tacho",
                                checked: preferences?.assetLocation?.hideTacho,
                            },

                            {
                                id: 2,
                                name: "Display Maintenance",
                                checked: preferences?.assetLocation?.hideVis,
                            }]}
                        onChange={onDisplayOptionsChange}
                        headingText="Manage My Fleet Display Options"
                        loading={loading}
                        switchListName="displayOptionsList"
                        disabled={!canEditProfile || saving}
                        dangerHelperText=""
                        headingLevel="h3"
                    />

                    <ShowHide
                        evaluator={canEditProfile}
                        show={(
                            <Fragment>
                                <AlertUnsavedData
                                    isDirty={isDirty}
                                    scrollToBottomOnCancel={true}
                                >
                                </AlertUnsavedData>

                                <ButtonSave text="Save"
                                    disabled={!canEditProfile || saving}
                                    simpleDisabled={!isDirty}
                                    loading={loading}
                                    onClick={savePreferences}
                                />
                            </Fragment>
                        )}
                    />
                </div>
            )}
        />
    );
}

Preferences.defaultProps = {
    assetProfile: {},
    saveProfile: () => { },
    loading: true,
    saving: false,
    assetTypeDisplay: {},
    assetOptions: {}
};

Preferences.propTypes = {
    assetProfile: PropTypes.object.isRequired,
    saveProfile: PropTypes.func.isRequired,
    assetTypeDisplay: PropTypes.array.isRequired,
    assetOptions: PropTypes.object.isRequired,
    loading: PropTypes.bool.isRequired,
    saving: PropTypes.bool.isRequired
};