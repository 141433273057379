import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { SetFiltering } from 'state/actions';
import { UserAccess, UserHasAccess } from "components/user-access";
import { PageRestricted } from "pages/errors/page-restricted";
import { FormDatePeriodSelector, FormLocationSelector, FormTextInput } from "components/form-fields"
import { Tooltip } from "components/tooltip";
import { Button, LinkButton } from "components/buttons"
import { Filter } from "components/filtering";
import { NotificationToaster, NotificationInline } from "components/notifications";
import { Intent, Position } from "@blueprintjs/core";
import { ListingPagination, ListingTable } from "components/listing";
import { AssetService, LinkService } from 'services';
import { KeyBlock } from "components/key/KeyBlock";
import { Numeric } from "components/formatting/Numeric";
import { DateToLocal } from "components/dates/DateToLocal";
import { PreferencesModal } from "components/preferences-modal/PreferencesModal";
import { KeyModal } from "./key-modal";
import { DayDetailModal } from "./day-detail-modal";
import { ReportList } from "components/reporting/ReportList";
import { AssetStatus } from 'components/status/AssetStatus';
import { useDebounce } from "hooks/useDebounce";
import classNames from "classnames";
import moment from "moment";
import axios from 'axios';
import Cookies from "js-cookie";
import "./Fleet.css"
import { InternalLink } from 'components/navigation';

export function Fleet() {
    const requiredActions = ["Fleet:View"];
    const canAccessPage = UserHasAccess(requiredActions);
    const firstColumnLabel = "ID/Registration";
    const calculatedColumnNames = ["KM without card"];
    let tableHeadersPrefix = [firstColumnLabel, ""];
    const initialDate = moment().startOf('isoWeek');
    const assetTypesMappings = [
        { "id": 1, "img": "hgv-scaled.svg", "name": "Heavy Goods Vehicle" },
        { "id": 2, "img": "lcv-scaled.svg", "name": "Light Commercial Vehicle" },
        { "id": 3, "img": "psv-scaled.svg", "name": "Public Service Vehicle" },
        { "id": 4, "img": "trailer-scaled.svg", "name": "Trailer" },
        { "id": 5, "img": "dst-scaled.svg", "name": "Dangerous Substances Tanker" },
        { "id": 6, "img": "crane-scaled.svg", "name": "Crane" },
        { "id": 7, "img": "tail lift-scaled.svg", "name": "Tail Lift" },
        { "id": 8, "img": "lift truck-scaled.svg", "name": "Lift Truck" },
        { "id": 9, "img": "winch-scaled.svg", "name": "Winch" },
        { "id": 10, "img": "hgv-scaled.svg", "name": "Vehicle" },
        { "id": 12, "img": "van-scaled.svg", "name": "Van" },
        { "id": 13, "img": "car-scaled.svg", "name": "Car" },
        { "id": 14, "img": "lifting equipment-scaled.svg", "name": "Lifting Equipment" },
        { "id": 15, "img": "trailer-scaled.svg", "name": "Light Trailer" }
    ];

    const periodSelectorDateFormat = "DD MMM YYYY";
    const defaultItemsPerPage = 25;
    const daysInWeek = 7;
    const saturdayInt = 6;
    const sundayInt = 7;
    const cellWidthIdentifier = 175;
    const cellWidthDefault = 100;
    const cellWidthDay = 40;
    const debounceTimeout = 750;
    const filterName = "assets";
    const filterSearchSelector = "fleetSearch";
    const fleetDateReduxName = "fleetDate";
    const mmfCookie = 'Use_New_MMF';
    const weekendDays = [saturdayInt, sundayInt];

    const [isInitialising, setIsInitialising] = useState(true);
    const [initialPeriodOffset, setInitialPeriodOffset] = useState(null);
    const [reduxLoaded, setReduxLoaded] = useState(false);

    const [isSearching, setIsSearching] = useState(true);
    const [locationsLoading, setLocationsLoading] = useState(true);
    const [useLocationDefaults, setUseLocationDefaults] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const debouncedSearchTerm = useDebounce(searchTerm, debounceTimeout);

    const [showFilters, setShowFilters] = useState(false);
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [pageFilters, setPageFilters] = useState([]);
    const [selectedLocations, setSelectedLocations] = useState([]);
    const [useHierarchy, setUseHierarchy] = useState(true);
    const inputSearchRef = useRef(null);
    const [startDate, setStartDate] = useState(null);
    const [searchResultData, setSearchResultData] = useState([]);

    const [lastSearchPayload, setLastSearchPayload] = useState(null);
    const [loadedAssetIds, setLoadedAssetIds] = useState([]);

    const refOverflowTable = useRef();
    const refHeaderTable = useRef();
    const refHeaderScrollListener = useRef();
    const refCellOverflow = useRef([]);
    const refHeaderOverflow = useRef([]);

    const [assetStatusesMappings, setAssetStatusesMappings] = useState([]);
    const [ownershipAttributeMappings, setOwnershipAttributeMappings] = useState([]);
    const [fleetSearchLoaded, setFleetSearchLoaded] = useState(false);
    const fleetSearch = useSelector(state => state.filters[filterSearchSelector]);

    const currentSearchTerm = useRef();
    const currentPageNo = useRef();
    const currentStartDate = useRef();
    const currentEndDate = useRef();
    const currentTableSortBy = useRef();
    const currentTableSortDir = useRef();
    const currentAppliedFilters = useRef();
    const currentLocations = useRef();

    // Listings objects:
    const [searchResult, setSearchResult] = useState([]);
    const [activeHeaders, setActiveHeaders] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalPages, setTotalPages] = useState(1);
    const [sortedBy, setSortedBy] = useState(firstColumnLabel);
    const [sortedDir, setSortedDir] = useState("A");
    const [sortableHeaders, setSortableHeaders] = useState([firstColumnLabel]);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const perPage = defaultItemsPerPage;
    const [columnClasses, setColumnClasses] = useState([]);
    const [columnWidths, setColumnWidths] = useState([cellWidthIdentifier, cellWidthDefault]);

    // Key Modal
    const [showKeyModal, setShowKeyModal] = useState(false);
    const [vehicleActivitiesForKeyModal, setVehicleActivitiesForKeyModal] = useState([]);

    // Report Downloading
    const [reportDownloading, setReportDownloading] = useState(false);

    // DisplayPreferencesModal
    const [displayPreferences, setDisplayPreferences] = useState([]);
    const [showPreferencesModal, setShowPreferencesModal] = useState(false);
    const [tachoWeeksToShow, setTachoWeeksToShow] = useState(4);
    const [preferencesUpdated, setPreferencesUpdated] = useState(false);

    // Day Detail Modal
    const [showDayDetailModal, setShowDayDetailModal] = useState(false);
    const [dayDetailModalAssetId, setDayDetailModalAssetId] = useState(0);
    const [dayDetailModalDate, setDayDetailModalDate] = useState("");

    const reduxDate = useSelector(state => state.filters[fleetDateReduxName]);
    const [locationSelectorOpen, setLocationSelectorOpen] = useState(false);

    const handleSavePreferences = (newPreferences, newTachoWeeksToShow) => {
        setDisplayPreferences(newPreferences);
        setTachoWeeksToShow(newTachoWeeksToShow);
        setPreferencesUpdated(true);
    };

    function formatPageFilters(statusesForAssets, assetOwnerships, assetTypes) {
        return [{
            "name": "Status",
            "displayName": "Status",
            "items": statusesForAssets
        },
        {
            "name": "Ownership",
            "displayName": "Ownership",
            "items": assetOwnerships
        },
        {
            "name": "AssetType",
            "displayName": "Type",
            "items": assetTypes
        }]
    }

    function processAssetOptions(response) {
        if (response != null) {
            setPageFilters(formatPageFilters(response.assetStatuses, response.assetOwnerships, response.assetTypes));
            setOwnershipAttributeMappings(response.assetOwnerships.map((x) => {
                return { "name": x.name, "colours": x.colour, "id": x.id, "className": x.style, "icon": x.icon }
            }));
            setAssetStatusesMappings(response.assetStatuses.map((x) => {
                return { "name": x.name, "colours": x.colour, "id": x.id }
            }));
        }
    }

    function processActivityTypes(response) {
        if (response != null) {
            setVehicleActivitiesForKeyModal(response.map((x) => {
                return { "name": x.name, "colours": x.colour, "id": x.id }
            }));
        }
    }

    function processTachoPreferences(response) {
        if (response != null) {
            setDisplayPreferences(response.displayOptions);
            setTachoWeeksToShow(response.preferences.tachoWeeksToShow);
        }
    }

    function checkForCookie(cookieName) {
        return Cookies.get(cookieName) !== undefined;
    }

    useEffect(() => {
        //We know the redux store has loaded, we only want to set the offset once
        if (!reduxLoaded) {
            setReduxLoaded(true);
            if (reduxDate && reduxDate.startDate) {
                setInitialPeriodOffset(reduxDate.startDate.diff(initialDate, 'weeks'));
            } else {
                setInitialPeriodOffset(0);
            }
        }
    }, [reduxDate]);

    useEffect(() => {
        recalculateHeaders();
    }, [displayPreferences, tachoWeeksToShow]);

    useEffect(() => {

        const scrollHeader = () => {
            if (refHeaderTable.current != null) {
                refHeaderTable.current.style.transform = `translateX(-${refOverflowTable.current.scrollLeft}px)`;
            }
        }

        const removeExistingListeners = () => {
            refOverflowTable.current?.removeEventListener("scroll", scrollHeader);
        }

        if (refOverflowTable.current && refHeaderScrollListener.current == null) {
            //Make sure we detach any existing listeners so its not applied twice
            removeExistingListeners();
            refOverflowTable.current.addEventListener("scroll", scrollHeader);
        }

    }, [refOverflowTable.current]);

    useEffect(() => {
        if (!canAccessPage) {
            return;
        }

        const redirectIfCookieDoesntExist = () => {
            const cookieExists = checkForCookie(mmfCookie);
            if (!cookieExists) {
                window.location.href = `${window.env.VISION_URL}/#/fleet`;
            }
        };

        redirectIfCookieDoesntExist();

        dispatch({ type: 'SITE_FULL_WIDTH' });

        axios.all([
            AssetService.getAssetOptions(),
            AssetService.getActivityTypes(),
            AssetService.getTachoPreferences()
        ]).then(axios.spread(function (assetOptionsResponse, activityTypesResponse, tachoPreferencesResponse) {
            processAssetOptions(assetOptionsResponse);
            processActivityTypes(activityTypesResponse);
            processTachoPreferences(tachoPreferencesResponse);
        })).catch(() => {
            NotificationToaster.show(Intent.DANGER, `Failed to load manage my fleet. Please refresh the page.`);
        }).finally(() => {
            setIsInitialising(false);
        });
    }, []);

    useEffect(() => {
        if (preferencesUpdated) {
            performSearch(true);
            setPreferencesUpdated(false);
        }
    }, [preferencesUpdated]);


    //FILTERS:
    const defaultFilters = [{ name: 'Status', displayName: 'Active', value: 1 }, { name: 'Status', displayName: 'Vehicle Off Road', value: 3 }]
    let selectedFilters = useSelector(state => state.filters["assets"]);
    const dispatch = useDispatch();
    if (selectedFilters.length === 0) {
        selectedFilters = defaultFilters;
    }
    dispatch(SetFiltering(filterName, selectedFilters));

    function onSelectedLocationsChange(locations) {
        setSelectedLocations(locations);
        setFleetSearchDispatch(locations, null, null, null, null, null);
    }

    function onToggleFilter() {
        setShowFilters((prevState) => {
            setFleetSearchDispatch(null, null, null, null, null, !prevState);
            return !prevState;
        })
    }

    function onFilterChange(listingFilters) {
        setAppliedFilters(listingFilters);
    }

    function onLocationListOpened() {
        setLocationSelectorOpen(true);
    }

    function onLocationListClose() {
        setLocationSelectorOpen(false);
    }

    //Headers Logic/Formatting:
    function recalculateHeaders() {
        if (!startDate) { return; }

        //When start date changes, update the calendar columns:
        let colWidths = [cellWidthIdentifier, cellWidthDefault];
        let calendarHeaders = [];
        for (let i = 0; i < (daysInWeek * tachoWeeksToShow); i++) {
            let thisDay = moment(startDate).add(i, 'days');
            let thisClassNames = "";
            if (weekendDays.includes(thisDay.isoWeekday())) {
                thisClassNames += "weekend";
            }
            calendarHeaders.push(<div key={`cal${thisDay.format("DD")}`} className={classNames(thisClassNames)}>{thisDay.format("ddd")}<br />{thisDay.format("DD")}</div>);
            colWidths.push(cellWidthDay);
        };

        const cellHasOverflow = (index) => {

            var cell = refHeaderOverflow.current[index];

            if (cell != null) {
                return cell.scrollWidth > cell.offsetWidth;
            }

            return false;
        }

        let formattedAdditionalHeaders = displayPreferences.filter(x => x.userSelected).sort((x, y) => x.userPriority - y.userPriority).map((x, headerIndex) => {
            colWidths.push(x.minWidth ?? cellWidthDefault);

            return <Tooltip key={x.name} content={x.name ?? ""} position="top" disabled={!cellHasOverflow(headerIndex)}>
                <div
                    ref={(el) => {
                        refHeaderOverflow.current[headerIndex] = el;
                    }}

                    className="additional-column"
                    style={{ width: x.minWidth }}>
                    {x.name}
                </div>
            </Tooltip>;
        });

        let additionalHeaderKeys = formattedAdditionalHeaders.map((x) => { return x.key.toString() });

        //map the additional columns
        setActiveHeaders([...tableHeadersPrefix, ...calendarHeaders, ...formattedAdditionalHeaders]);
        setSortableHeaders([...tableHeadersPrefix, ...additionalHeaderKeys]);
        setColumnClasses(["base-column", "asset-actions-column base-column", ...Array(calendarHeaders.length).fill("calendar-day"), ...Array(formattedAdditionalHeaders.length).fill("")]);
        setColumnWidths(colWidths);
    }

    useEffect(() => {
        if (!startDate || isInitialising || locationsLoading) { return; }

        recalculateHeaders();
    }, [startDate, isInitialising, locationsLoading]);


    function getIdField(assetId, registrationNumber) {
        return (
            <Tooltip position={Position.TOP} content={`Asset Profile`}>
                <InternalLink link={LinkService.getVehicleProfileUrl(assetId, 'details')}>{registrationNumber}</InternalLink>
            </Tooltip>
        );
    }

    //Columns Logic/Formatting:
    function getVehicleIcon(assetTypeId) {
        let img = "unknown-vehicle-scaled.svg";
        let vehicleType = "unknown";

        var assetType = assetTypesMappings.find(o => {
            return o.id === assetTypeId
        });

        if (assetType) {
            img = assetType.img;
            vehicleType = assetType.name;
        }
        return (
            <div className="vehicle-icon">
                <Tooltip position={Position.TOP} content={vehicleType}>
                    <img src={`/icons/vehicles/${img}`} alt={vehicleType} />
                </Tooltip>
            </div>
        );
    }

    function getCalendarLink(assetId, date) {
        return (
            <Tooltip position={Position.TOP} content={`Asset Calendar`}>
                <LinkButton
                    href={`fleet/${assetId}/calendar`}
                    icon={"calendar"}
                    externalLink={false}
                    minimal={true}
                    iconOnly={true}
                    text="Calendar"
                    large={false}
                />
            </Tooltip>
        );
    }

    function getCalendarBlockPopoverMessage(activityDescriptions) {
        return (
            <div>
                <p>The data for today is:</p>
                <ul>
                    {activityDescriptions.map((x) => {
                        return <li key={x}>{x}</li>
                    })}
                </ul>
            </div>
        );
    }

    function displayDayDetailModal(assetId, date) {
        setDayDetailModalAssetId(assetId);
        setDayDetailModalDate(date);
        setShowDayDetailModal(true);
    }

    function hideDayDetailModal() {
        setDayDetailModalAssetId(0);
        setDayDetailModalDate("");
        setShowDayDetailModal(false);
    }

    function getCalendarBlock(activityTypes, isAwaitingData, assetId, date) {
        let toolTipDisabled = false;
        if (!activityTypes || activityTypes.length === 0) {
            activityTypes = [{ name: "", colours: '#FFFFFF' }];
            toolTipDisabled = true;
        }
        let formattedDate = date.format("YYYY-MM-DD")
        return (<Tooltip content={getCalendarBlockPopoverMessage(activityTypes.map(x => x.name))} disabled={toolTipDisabled} >
            <KeyBlock colours={activityTypes.map(x => x.colours)} onClick={() => displayDayDetailModal(assetId, formattedDate)} size={14} loading={isAwaitingData} selectable />
        </Tooltip>
        );
    }

    function getCalendarRowsItems(calendarItems, assetId) {
        let isAwaitingData = calendarItems === null;
        if (!calendarItems) {
            calendarItems = Array(daysInWeek * tachoWeeksToShow).fill([]);
        }

        let rows = [];
        for (let i = 0; i < daysInWeek * tachoWeeksToShow; i++) {
            let activities = [];
            if (calendarItems[i] !== undefined) {
                activities = calendarItems[i].map(x => {
                    return vehicleActivitiesForKeyModal.find(y => y.id === x);
                });
            }
            rows.push(getCalendarBlock(activities, isAwaitingData, assetId, moment(startDate).add(i, 'd')));
        }
        return rows;
    }

    function formatColumnDataFromSearchResult(apiData) {
        return apiData.map((x) => {
            return {
                key: x.assetId,
                status: x.assetStatus,
                ownership: x.assetOwnership,
                assetType: x.assetType,
                registrationNumber: x.registrationNumber,
                additionalColumns: x.columns,
                calendarItems: null
            }
        });
    }

    function mapColumnRows(row, rowIndex) {

        let baseColumns = [
            <div className="nowrap inline-items no-spacing" key={`statusIcon_${row.key}`}>
                <AssetStatus statusId={row.status.id} ownershipId={row.ownership.id} isLoading={isInitialising} statusAttributeMappings={assetStatusesMappings} ownershipAttributeMappings={ownershipAttributeMappings} tooltipPosition={Position.RIGHT} />
                {getIdField(row.key, row.registrationNumber)}
            </div>,
            <div className="nowrap inline-items no-spacing" key={`Links_${row.key}`}>
                {getVehicleIcon(row.assetType.id)}
                {getCalendarLink(row.key, startDate)}
            </div>, ...getCalendarRowsItems(row.calendarItems, row.key)
        ];

        let noOfBaseColumns = baseColumns.length;

        const cellHasOverflow = (cellRowIndex, cellColIndex) => {
            var rowRefs = refCellOverflow.current[cellRowIndex];

            if (rowRefs != null) {
                var cell = rowRefs[cellColIndex];
                if (cell != null) {
                    return cell.scrollWidth > cell.offsetWidth;
                }
            }

            return false;
        }

        let additionalRows = row.additionalColumns.map((x, colIndex) => {
            if (!displayPreferences) {
                return x.value;
            }
            let preferenceItem = displayPreferences.find(y => y.name === x.name);
            let format = "none";
            if (preferenceItem) {
                format = preferenceItem.format;
            }

            let value = null;

            switch (format) {
                case "DateTime":
                    value = <DateToLocal format="DD/MM/YYYY" invalidDateString="">{x.value}</DateToLocal>;
                    break;
                case "Int":
                    value = <Numeric>{x.value}</Numeric>;
                    break;
                default:
                    value = x.value;
                    break;
            }

            const node = <div
                ref={(el) => {
                    refCellOverflow.current[rowIndex] = refCellOverflow.current[rowIndex] || [];
                    refCellOverflow.current[rowIndex][colIndex] = el;
                }}
                className="additional-column"
                style={{ width: columnWidths[noOfBaseColumns + colIndex] }}
            >
                {value}
            </div>;

            return <Tooltip key={`col-${x.name}`} content={value} position="top" disabled={!cellHasOverflow(rowIndex, colIndex)}>{node}</Tooltip>;
        });
        return baseColumns.concat(additionalRows);
    }

    //Search logic:
    function getSearchPayload() {
        let filters = appliedFilters.map(x => {
            return { key: x.name, value: x.value }
        });

        if (selectedLocations != null) {
            selectedLocations.forEach(function (l) {
                filters = [...filters,
                { key: "Location", value: l }
                ];
            });
        }

        return {
            requestCount: perPage,
            pageNumber: currentPageNumber,
            searchTerm: searchTerm,
            sortField: sortedBy === firstColumnLabel ? "AssetIdentifier" : sortedBy,
            sortDirection: sortedDir,
            filters: filters,
            startDate: startDate.format("YYYY-MM-DD"),
            endDate: moment(startDate).add((daysInWeek * tachoWeeksToShow) - 1, 'days').format("YYYY-MM-DD")
        };
    }

    useEffect(() => {
        if (searchResultData !== undefined && assetStatusesMappings.length > 0 && ownershipAttributeMappings.length > 0) {
            const data = searchResultData.map((d, rowIndex) => {
                return [...mapColumnRows(d, rowIndex)];
            });
            setSearchResult(data);
        }
    }, [searchResultData, assetStatusesMappings, ownershipAttributeMappings]);


    function performSearch(forceSearch) {
        const searchPayload = getSearchPayload();

        if (!forceSearch && (!searchPayload ||
            (currentPageNo.current === searchPayload.pageNumber &&
                currentSearchTerm.current === searchPayload.searchTerm &&
                currentStartDate.current === searchPayload.startDate &&
                currentEndDate.current === searchPayload.endDate &&
                currentTableSortBy.current === searchPayload.sortField &&
                currentTableSortDir.current === searchPayload.sortDirection &&
                currentAppliedFilters.current === appliedFilters.length &&
                currentLocations.current === selectedLocations.length))
        ) {
            //If none of the values have changed, then 2 have been updated at once or it's the initial load from Redux, so prevent the listing from performing another call.
            return;
        }

        setIsSearching(true);

        if (currentPageNo.current === searchPayload.pageNumber && currentStartDate.current === searchPayload.startDate && currentEndDate.current === searchPayload.endDate) {
            //The reason for the reload isn't the page number or date changing, so we can reset the page number to 1 but we don't want to reload the page when it happens!
            searchPayload.pageNumber = 1;
            currentPageNo.current = 1;
            setCurrentPageNumber(1);
        } else {
            currentPageNo.current = searchPayload.pageNumber;
        }

        currentSearchTerm.current = searchPayload.searchTerm;
        currentStartDate.current = searchPayload.startDate;
        currentEndDate.current = searchPayload.endDate;
        currentTableSortBy.current = searchPayload.sortField;
        currentTableSortDir.current = searchPayload.sortDirection;
        currentAppliedFilters.current = appliedFilters.length;
        currentLocations.current = selectedLocations.length;

        AssetService.searchAssetsWithPreferences(searchPayload).then((response) => {

            if (response.totalCount === 0) {
                setSearchResultData([]);
                setSearchResult([]);
                setTotalRecords(response.totalCount);
                return;
            }

            if (response.data != null) {
                setSearchResultData(formatColumnDataFromSearchResult(response.data));
            }
            setTotalRecords(response.totalCount);
            setLastSearchPayload(searchPayload);
            setLoadedAssetIds(response.data.map(x => x.assetId));
        }).catch((error) => {
            NotificationToaster.show(Intent.DANGER, `Asset search failed. Please try again. ${error} `);
        }).finally(function () {
            setIsSearching(false);
        });
    }

    function AddTachoDataToSearchResultData(prefSearchResultData, tachoResultData) {
        prefSearchResultData.forEach((x) => {
            let calendarItems = tachoResultData.find(y => y.assetId === x.key);
            x.calendarItems = calendarItems ? calendarItems.activity : null;
        });
        return prefSearchResultData;
    }

    function refreshCalendarData(response) {
        setSearchResultData((srd) => {
            let newSrd = [...srd];
            return AddTachoDataToSearchResultData(newSrd, response);
        });
    }

    function doCalendarRefresh() {
        if (loadedAssetIds.length === 0) { return; }

        refreshCalendarData([]);
        AssetService.searchTachoDataWithAssetIds(loadedAssetIds, startDate.format("YYYY-MM-DD"), tachoWeeksToShow).then((response) => {
            refreshCalendarData(response);
        }).catch((error) => {
            NotificationToaster.show(Intent.DANGER, `Failed to read Tacho data. Calendar may not be populated correctly. Please refresh page and try again. ${error} `);
        }).finally(function () {
            setIsSearching(false);
        });
    }

    useEffect(() => {
        if (selectedLocations.length > 0) {
            setLocationsLoading(false);
        }
    }, [selectedLocations]);


    useEffect(() => {
        doCalendarRefresh();
    }, [loadedAssetIds]);

    useEffect(() => {
        if (displayPreferences.filter(x => x.userSelected && calculatedColumnNames.includes(x.name)).length > 0) {
            //If the user has any calculated columns displayed, we need to update them as well as the calendar items:
            performSearch();
        }
        else {
            doCalendarRefresh();
        }
    }, [startDate, tachoWeeksToShow]);

    useEffect(() => {
        if (isInitialising || locationsLoading || !fleetSearchLoaded || locationSelectorOpen) { return; }
        performSearch();
    }, [debouncedSearchTerm, appliedFilters, selectedLocations, sortedBy, sortedDir, perPage, currentPageNumber, locationsLoading, locationSelectorOpen, isInitialising])

    useEffect(function () {
        if (fleetSearch && !fleetSearchLoaded) {
            setSelectedLocations(fleetSearch.locations);
            setSearchTerm(fleetSearch.searchTerm);
            setSortedBy(fleetSearch.sortedBy);
            setCurrentPageNumber(fleetSearch.currentPageNumber);
            setSortedDir(fleetSearch.sortedDir);
            setShowFilters(fleetSearch.showFilters);
            setUseLocationDefaults(false);
        } else {
            setUseLocationDefaults(true);
        }
        if (!fleetSearchLoaded) {
            setFleetSearchLoaded(true);
        }
    }, [fleetSearch]);

    function setFleetSearchDispatch(newLocations, newSearchTerm, newSortedBy, newCurrentPageNumber, newSortedDir, newShowFilters) {
        dispatch(SetFiltering(filterSearchSelector, {
            locations: newLocations ?? selectedLocations,
            searchTerm: newSearchTerm ?? searchTerm,
            sortedBy: newSortedBy ?? sortedBy,
            currentPageNumber: newCurrentPageNumber ?? currentPageNumber,
            sortedDir: newSortedDir ?? sortedDir,
            showFilters: newShowFilters ?? showFilters
        }));
    }

    function onSearchTextChange(item) {
        setSearchTerm(item);
        setFleetSearchDispatch(null, item, null, null, null, null);
    }

    function openKeyModal() {
        setShowKeyModal(true);
    }

    function closeKeyModal() {
        setShowKeyModal(false);
    }

    function onWeekChange(periodStart) {
        setIsSearching(true);
        let dt = moment(periodStart, periodSelectorDateFormat);
        if (!dt.isSame(startDate)) {
            dispatch(SetFiltering(fleetDateReduxName, { startDate: dt }));
            setStartDate(dt);
        }
    }

    function onPagingChange(page) {
        setCurrentPageNumber(page);
        setFleetSearchDispatch(null, null, null, page, null, null);
    }

    function onTableSort(header, direction) {
        let sortBy = (typeof header === 'string') ? header : header.props.content;
        setSortedBy(sortBy);
        setSortedDir(direction);
        setFleetSearchDispatch(null, null, sortBy, null, direction, null);
    }
    function openPreferencesModal() {
        setShowPreferencesModal(true);
    }

    function closePreferencesModal() {
        setShowPreferencesModal(false);
    }

    function onReportDownloading(isDownloading) {
        setReportDownloading(isDownloading);
    }

    useEffect(() => {
        setTotalPages(Math.ceil(totalRecords / perPage));
    }, [totalRecords, perPage])

    return (
        <UserAccess perform={requiredActions}
            yes={() => (
                <>
                    <div className="contained-content">
                        <div className="contained-content-static">

                            <h1>Manage My Fleet</h1>
                            
                            <div className={classNames("inline-items", {
                                "spacer-bottom-small": !showFilters
                            })}>
                                <div className="inline-item fill">
                                    <div className="form-field-full">
                                        <FormTextInput inputRef={inputSearchRef} placeholder="Search Fleet" onChange={(x) => onSearchTextChange(x.target.value)} value={searchTerm} large disabled={isInitialising} icon="search" id="user-search-field" />
                                    </div>
                                </div>
                                <div className="inline-item">
                                    <FormLocationSelector
                                        businessArea={"Tacho"}
                                        loading={isInitialising}
                                        onLocationListOpened={onLocationListOpened}
                                        onLocationListClose={onLocationListClose}
                                        selectedLocations={selectedLocations}
                                        setSelectedLocations={onSelectedLocationsChange}
                                        useLocationDefaults={useLocationDefaults}
                                        useHierarchy={useHierarchy}
                                        setUseHierarchy={setUseHierarchy}
                                        locationDefaultsToTrue={isInitialising ? null : true}
                                    />
                                </div>
                                <div className="inline-item">
                                    <Tooltip content="Filter" position="right">
                                        <Button icon="filter" minimal onClick={onToggleFilter} className={classNames({ "active": appliedFilters.length > 0 })} />
                                    </Tooltip>
                                </div>
                            </div>
                            <Filter filterName={filterName} visible={showFilters} filters={pageFilters} onUpdate={onFilterChange} />

                            <div className="inline-items spacer-bottom">
                                <div className="inline-item fill">
                                    <div className="inline-items">
                                        <FormDatePeriodSelector
                                            id="listing-period"
                                            periodType="weeks"
                                            periodsToShow={tachoWeeksToShow}
                                            startDate={initialDate.toString()}
                                            onChange={onWeekChange}
                                            dateFormat={periodSelectorDateFormat}
                                            showCalendar={true}
                                            showText={false}
                                            initialPeriodOffset={initialPeriodOffset}
                                        />
                                        <div className="inline-divide"></div>
                                        <div className="button-row">
                                            <Tooltip content="Add new asset">
                                                <LinkButton intent="primary" large={false} icon="plus" iconOnly href="/asset/create/business-area/1" id="asset-create" />
                                            </Tooltip>
                                            <Tooltip content="Reports">
                                                <ReportList compact lastSearchPayload={lastSearchPayload} onReportDownloadingChange={onReportDownloading} pageName='Fleet' large={false} />
                                            </Tooltip>
                                            <Tooltip content="Key">
                                                <Button intent="secondary" icon="properties" iconOnly id="key" onClick={openKeyModal} className="key-button" large={false} />
                                            </Tooltip>
                                        </div>
                                        <div className="inline-divide"></div>
                                        <div className="button-row">
                                            <Button text="Column Preferences" minimal={true} large={false} intent="secondary" icon="cog" id="configure-properties" className="configure-prefs-button" onClick={openPreferencesModal} />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <ListingPagination currentPage={currentPageNumber} totalPages={totalPages} onPageChange={onPagingChange} loadingData={isSearching || isInitialising} className="pagination-inline" />
                                </div>
                            </div>


                            <NotificationInline intent="success" text="Your report has been queued, and will download as soon as it's ready." show={reportDownloading} />

                            <div ref={refHeaderTable}>
                                <ListingTable
                                    id="listing-table-fleet-headers"
                                    headers={activeHeaders}
                                    data={[]}
                                    totalRecordCount={1}
                                    loadingData={false}
                                    sortable
                                    sortableHeaders={sortableHeaders}
                                    sortedBy={sortedBy}
                                    sortedDir={sortedDir}
                                    onSort={onTableSort}
                                    columnClasses={columnClasses}
                                    columnWidths={columnWidths}
                                    loadingClass={searchResultData.length === 0 ? "bp3-skeleton" : "bp3-skeleton loading"}
                                    pageable={false}
                                    fitToContent
                                />
                            </div>

                        </div>

                        <div className="contained-content-auto" ref={refOverflowTable}>
                            <ListingTable
                                id="listing-table-fleet"
                                headers={[]}
                                data={searchResult}
                                totalRecordCount={totalRecords}
                                onPagingChange={onPagingChange}
                                loadingData={isSearching || isInitialising}
                                noDataHeading="No Assets Found"
                                noDataMessage="No assets for this search criteria"
                                sortable
                                sortableHeaders={sortableHeaders}
                                sortedBy={sortedBy}
                                sortedDir={sortedDir}
                                onSort={onTableSort}
                                columnClasses={columnClasses}
                                columnWidths={columnWidths}
                                loadingClass={searchResultData.length === 0 ? "bp3-skeleton" : "bp3-skeleton loading"}
                                pageable={false}
                                fitToContent
                            />
                        </div>
                    </div>

                    <KeyModal
                        showKeyModal={showKeyModal}
                        closeKeyModal={closeKeyModal}
                        isInitialising={isInitialising}
                        ownershipForKeyModal={ownershipAttributeMappings}
                        assetStatusesForKeyModal={assetStatusesMappings}
                        vehicleActivitiesForKeyModal={vehicleActivitiesForKeyModal}
                    />

                    <DayDetailModal
                        showDayDetailModal={showDayDetailModal}
                        closeDayDetailModal={hideDayDetailModal}
                        assetId={dayDetailModalAssetId}
                        date={dayDetailModalDate}
                        statusAttributeMappings={assetStatusesMappings}
                        ownershipAttributeMappings={ownershipAttributeMappings}
                        lastSearchPayload={lastSearchPayload}
                        vehicleActivityTypes={vehicleActivitiesForKeyModal}
                        key={dayDetailModalAssetId + dayDetailModalDate}
                    />

                    <PreferencesModal
                        isOpen={showPreferencesModal}
                        onClose={closePreferencesModal}
                        displayPreferences={displayPreferences}
                        numberOfWeeks={tachoWeeksToShow}
                        cancelButtonIntent={Intent.NONE}
                        onSave={handleSavePreferences}
                        onSavePreferences={AssetService.saveTachoPreferences}
                        onPreferencesSaved={() => setPreferencesUpdated(true)}
                        preferencesKey="Tacho"
                    />
                </>
            )}
            no={() => <PageRestricted />}
        />
    );
}